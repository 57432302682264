import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { FC } from "react";

type Props = {
  sorter: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
};

export const AdminSorterSelector: FC<Props> = (props) => {
  const { sorter, onChange } = props;

  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">ソート基準</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={sorter}
        onChange={onChange}
        row
      >
        <FormControlLabel value="name" control={<Radio />} label="名前順" />
        <FormControlLabel value="rank" control={<Radio />} label="ランク" />
      </RadioGroup>
    </FormControl>
  );
};
