import { Typography } from "@mui/material";
import { FC } from "react";

export const ScreenResult: FC<Record<string, unknown>> = (props) => {
  console.log("screen result");
  return (
    <>
      <Typography variant="h3" sx={{ mt: 2 }}>
        最終結果
      </Typography>
    </>
  );
};
