import Problem1 from "../../../static/riddler2/prob1-32.png";
import Problem2 from "../../../static/riddler2/prob1-34.png";
import Problem3 from "../../../static/riddler2/prob1-37.png";
import Problem4 from "../../../static/riddler2/prob1-39.png";
import Problem5 from "../../../static/riddler2/prob1-41.png";
import Problem6 from "../../../static/riddler2/prob1-43.png";
import Problem7 from "../../../static/riddler2/prob1-53.png";
import Problem9 from "../../../static/riddler2/prob2-11.png";
import Problem10 from "../../../static/riddler2/prob2-19.png";
import Problem8 from "../../../static/riddler2/prob2-3.png";
import Problem12 from "../../../static/riddler2/prob6-13.png";
import Problem13 from "../../../static/riddler2/prob6-32.png";
import Problem11 from "../../../static/riddler2/prob6-7.png";
import Problem14 from "../../../static/riddler2/prob7-11.png";
import Problem15 from "../../../static/riddler2/prob7-30.png";
import Problem16 from "../../../static/riddler2/prob7-34.png";
import Problem17 from "../../../static/riddler2/prob7-39.png";
import Problem18 from "../../../static/riddler2/prob7-44.png";
import Problem19 from "../../../static/riddler2/prob7-55.png";
import Problem20 from "../../../static/riddler2/prob8-34.png";

export const questions2nd_1 = [
  {
    id: 1,
    image: Problem1,
    answers: ["きりん", "キリン", "麒麟"],
    hint: "な[し]/[か]き → しか, か[き]/[りん]ご → ???",
  },
  {
    id: 2,
    image: Problem2,
    answers: ["いのしし", "イノシシ", "猪"],
    hint: "2つの枠を組み合わせてみましょう。4文字のカタカナになります",
  },
  {
    id: 3,
    image: Problem3,
    answers: ["かえる", "カエル", "蛙"],
    hint: "それぞれの服は大きさが違うようです",
  },
  {
    id: 4,
    image: Problem4,
    answers: ["ライオン", "らいおん", "LION", "lion", "🦁"],
    hint: "空いている場所にはNO 17が入りそうです。？がひっくり返っていますね",
  },
  {
    id: 5,
    image: Problem5,
    answers: ["いぬ", "イヌ", "犬"],
    hint: "上はスモールが小に変換されているようです。同じように変換してみましょう",
  },
  {
    id: 6,
    image: Problem6,
    answers: ["あしか", "アシカ"],
    hint: "赤に順に「う」「さ」「ぎ」を入れてみましょう",
  },
  {
    id: 7,
    image: Problem7,
    answers: ["となかい", "トナカイ"],
    hint: "上の画像は「こ」の「うえ」に「ん」があるようです",
  },
  {
    id: 8,
    image: Problem8,
    answers: ["きつつき", "キツツキ"],
    hint: "矢印は読む向きを表しているようです",
  },
  {
    id: 9,
    image: Problem9,
    answers: ["ひよこ", "ヒヨコ"],
    hint: "上には「コーヒー」が入ります",
  },
  {
    id: 10,
    image: Problem10,
    answers: ["まうす", "マウス", "mouse", "MOUSE", "ねずみ", "ネズミ", "鼠"],
    hint: "上の画像は「ますく」を表してそうですね",
  },
];

export const questions2nd_2 = [
  {
    id: 1,
    image: Problem11,
    answers: ["いか", "イカ", "烏賊"],
    hint: "いぬとあしかが答えの問題でした",
  },
  {
    id: 2,
    image: Problem12,
    answers: ["ふな", "フナ", "鮒"],
    hint: "カタカナを数字の数だけ前後させましょう",
  },
  {
    id: 3,
    image: Problem13,
    answers: ["やご", "ヤゴ", "蛹"],
    hint: "左のイラストは「Tシャツ」、右は「Y」シャツです",
  },
  {
    id: 4,
    image: Problem14,
    answers: ["いのしし", "イノシシ", "猪", "INOSISI", "inosisi"],
    hint: "?にはいる部分をそのまま読めばよさそうです。さっきも出ましたね",
  },
  {
    id: 5,
    image: Problem15,
    answers: ["虫", "むし", "ムシ"],
    hint: "「中」を入れてみましょう",
  },
  {
    id: 6,
    image: Problem16,
    answers: ["こい", "コイ", "鯉"],

    hint: "上のイラストは「くい」ですね",
  },
  {
    id: 7,
    image: Problem17,
    answers: ["いんどぞう", "インドゾウ", "インド象"],
    hint: "「こ」の中に「インコ」がいますね",
  },
  {
    id: 8,
    image: Problem18,
    answers: ["ねこ", "ネコ", "猫"],
    hint: "ハエのイラストは「羽」を表しているようです。答えは2文字",
  },
  {
    id: 9,
    image: Problem19,
    answers: ["きつね", "キツネ", "狐"],
    hint: "「カタカナ」が入ります。上の2つと下の3つは違う法則のようです",
  },
  {
    id: 10,
    image: Problem20,
    answers: ["くま", "クマ", "熊"],
    hint: "矢印の方向にも意味があります",
  },
];
