import { Grid, Typography } from "@mui/material";
import { FC } from "react";

import { SubmissionResult } from "../../admin/component/SubmissionResult";
import { imageRoot } from "../v2arena";

type DisplayProblemProps = {
  answers: string[];
  displaySubmission?: boolean;
  roomId: string;
  probId: string;
  imgUrls?: string[] | null;
  page: number;
};

export const ScreenDisplayExplanation: FC<DisplayProblemProps> = (props) => {
  const { answers, roomId, probId, imgUrls, page = 0 } = props;
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <SubmissionResult roomId={roomId} probId={probId} />
        </Grid>
        {imgUrls && imgUrls[page] && imgUrls[page] !== "" && (
          <Grid item xs={8}>
            <img
              src={imageRoot + imgUrls[page]}
              alt="problem"
              style={{
                objectFit: "contain",
                width: "60vw",
                height: "70vh",
                margin: "auto",
              }}
            />
            <Typography variant="h4">{answers.join(", ")}</Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};
