import { Button } from "@mui/material";
import { FC } from "react";

import { RawProblem } from "../../../type";

type RecommendedButtonProps = {
  displayMode: string;
  changeDisplayMode: (mode: string) => void;
  changeCurrentProblemId: (diff: number) => void;
  changeCloseImage: () => void;
  changeAdvancePage: () => void;
  page: number;
  problems: RawProblem[];
  currentProblemId: string;
};

export const RecommendedButton: FC<RecommendedButtonProps> = (props) => {
  const {
    displayMode,
    page,
    changeDisplayMode,
    changeCurrentProblemId,
    changeCloseImage,
    changeAdvancePage,
    problems,
    currentProblemId,
  } = props;

  const problem = problems.find((e) => e.problemId === currentProblemId);
  const beforeImage =
    (problem?.beforeImage ?? "") === ""
      ? []
      : problem?.beforeImage.split(",") ?? [];
  switch (displayMode) {
    case "wait":
      return (
        <>
          {page < beforeImage.length - 1 ? (
            <Button
              onClick={() => changeAdvancePage()}
              variant="contained"
              sx={{
                m: 1,
              }}
            >
              次のページへ
            </Button>
          ) : (
            <Button
              onClick={() => changeDisplayMode("prob")}
              variant="contained"
              sx={{
                m: 1,
              }}
            >
              問題文表示
            </Button>
          )}
        </>
      );
    case "prob":
      return (
        <>
          <Button
            onClick={() => changeDisplayMode("finishProblem")}
            variant="contained"
            sx={{
              m: 1,
            }}
          >
            問題を終了
          </Button>
          {/* change closeImage */}
          <Button
            onClick={() => changeCloseImage()}
            variant="contained"
            sx={{
              m: 1,
            }}
          >
            画像を非表示
          </Button>
        </>
      );
    case "finishProblem":
      return (
        <Button
          onClick={() => changeDisplayMode("explain")}
          variant="contained"
          sx={{
            m: 1,
          }}
        >
          解説表示
        </Button>
      );
    case "explain":
    case "explain-submission":
      return (
        <>
          {page < (problem?.answerImage ?? "")?.split(",").length - 1 ? (
            <Button
              onClick={() => changeAdvancePage()}
              variant="contained"
              sx={{
                m: 1,
              }}
            >
              次のページへ
            </Button>
          ) : (
            <Button
              onClick={() => changeCurrentProblemId(+1)}
              variant="contained"
              sx={{
                m: 1,
              }}
            >
              次の問題へ
            </Button>
          )}
        </>
      );
    case "finish":
      return <p>イベントは終了です。あとはランキング更新・表示しましょう！</p>;
    default:
      return <></>;
  }
};
