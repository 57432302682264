import { Button, Grid, TextField } from "@mui/material";
import { FC, useCallback, useState } from "react";

import { googleSheetApiKey } from "../../../api/googleSheetApiKey";

type Props = {
  id: string;
  fetchValue: (value: string[][]) => void;
};

export const SpreadSheetLoader: FC<Props> = (props) => {
  const { fetchValue } = props;

  const [spreadsheetInput, setSpreadsheetInput] = useState<string>("");

  const loadSpreadsheet = useCallback(
    async (inputUrl: string) => {
      // sheet v4
      const apiKey =
        process.env.REACT_APP_GOOGLE_SHEETS_API_KEY ?? googleSheetApiKey; // APIキー
      // check is url
      try {
        const url = new URL(inputUrl);
        const id = url.pathname.split("/")[3];
        const sheet = url.pathname.split("/")[5];
        const range = url.searchParams.get("range");
        const key = url.searchParams.get("key");
        const gid = url.searchParams.get("gid") ?? url.hash.split("=")[1];

        console.log(id, sheet, range, key, gid);
        if (!id) {
          return;
        }
        const baseUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}?key=${apiKey}`;
        const response = await fetch(baseUrl);
        if (!response?.ok) {
          return;
        }
        const json = await response.json();
        if (!json.sheets) {
          return;
        }
        console.log("load!");

        // gid(SheetId) to sheetTitle
        const sheetName = json.sheets.find(
          (e: any) => e.properties.sheetId === Number(gid)
        ).properties.title;
        const sheetUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${sheetName}?key=${apiKey}`;
        const sheetResponse = await fetch(sheetUrl);
        const sheetJson = await sheetResponse.json();
        fetchValue(sheetJson.values);
        setSpreadsheetInput("");
      } catch (e) {
        console.error(e);
        return;
      }
    },
    [fetchValue, process.env.REACT_APP_GOOGLE_SHEETS_API_KEY, googleSheetApiKey]
  );

  return (
    <>
      <Grid item sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="スプレッドシートURL"
          value={spreadsheetInput}
          onChange={(e) => setSpreadsheetInput(e.target.value)}
          sx={{ width: 400, mt: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            loadSpreadsheet(spreadsheetInput);
          }}
        >
          読み込み
        </Button>
      </Grid>
    </>
  );
};
