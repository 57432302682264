import {
  Timestamp,
  doc,
  onSnapshot,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";

import { db } from "..";

export const getServerTime = async (id: string): Promise<Date> => {
  // ダミーのコレクションにサーバー時刻を書き込む
  const ref = doc(db, "dummy_for_time_get", id ?? "player"); // 任意のダミーコレクション名
  await setDoc(ref, {
    timestamp: serverTimestamp(),
  });
  return new Promise((resolve, reject) => {
    try {
      // 書き込みが終わったらデータを取得して返す
      onSnapshot(ref, (snapshot) => {
        const timestamp = snapshot.data({
          serverTimestamps: "estimate",
        })?.timestamp as Timestamp;
        resolve(timestamp.toDate());
      });
    } catch (err) {
      reject(err);
    }
  });
};
