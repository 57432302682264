import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import InsightsIcon from "@mui/icons-material/Insights";
import MenuIcon from "@mui/icons-material/Menu";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CssBaseline,
  Grid,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  tableCellClasses,
  useTheme,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { FC, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useStatePersist } from "use-state-persist";

import { Problem, RawProblem, Submit, V2Room } from "../../../type";
import { db } from "../../..";
import { convertRawProblemToProblem } from "../common/convertProblem";
import { getPagePoint } from "../score/pagePoint";
import { imageRoot } from "../v2arena";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

type Stats = Submit & {
  name: string;
  rank: number;
  rankPoint: number;
  timePoint: number;
  pagePoint?: number;
  totalPoint: number;
};

const eventData: {
  [key: string]: {
    title: string;
    rooms: {
      roomId: string;
      name: string;
      totalScoreType?: "int" | "float";
    }[];
  };
} = {
  hnkw: {
    title: "関西早押し謎解き交流会",
    rooms: [
      {
        roomId: "oitaku",
        name: "おいたくセット",
      },
      {
        roomId: "hit",
        name: "ひっとセット",
      },
      {
        roomId: "sugiyama",
        name: "杉山セット",
      },
      {
        roomId: "guma",
        name: "グマセット",
      },
      {
        roomId: "minchin",
        name: "ミンチンセット",
      },
      {
        roomId: "nagoma2",
        name: "ナゴマセット",
      },
      {
        roomId: "io",
        name: "ぃぉセット",
      },
      {
        roomId: "shirusesu",
        name: "しるせすセット",
      },
      {
        roomId: "naoto",
        name: "なおとセット",
      },
      {
        roomId: "kanzume",
        name: "缶詰セット",
      },
      {
        roomId: "tomasil",
        name: "とまと&シルクトセット",
      },
      {
        roomId: "ippei",
        name: "いっぺぃセット",
      },
      {
        roomId: "ayumu",
        name: "あゆむセット",
      },
    ],
  },
  nazotokiSession4: {
    title: "第4回 早押し謎解き交流会",
    rooms: [
      {
        roomId: "kocho",
        name: "コーチョーセット",
      },
      {
        roomId: "mitchy",
        name: "ミッチーセット",
        totalScoreType: "int",
      },
      {
        roomId: "rinddle",
        name: "リンドウセット",
        totalScoreType: "int",
      },
    ],
  },
};

const loadSubmits = async (roomId: string, problemId: string) => {
  const colref = collection(
    db,
    "v2rooms",
    roomId,
    "submits",
    problemId,
    "users"
  );
  const submits = await getDocs(colref);
  return submits.docs.map((doc) => ({
    id: doc.id,
    data: doc.data() as Submit,
  }));
};

const toNewSubmits = (
  prev: Stats[][],
  submit: { id: string; data: Submit }[],
  problem: Problem,
  value: number
) => {
  const newSubmits = [...prev];
  newSubmits[value] = submit
    .map((sub) => ({
      ...sub.data,
      name: sub.id,
      rank: -1,
      rankPoint: 0,
      timePoint: 0,
      pagePoint: problem.pagePoint ? 0 : undefined,
      totalPoint: 0,
    }))
    .sort((a, b) => {
      return -(a.submitTime - b.submitTime);
    });

  let rank = 0;
  newSubmits[value]
    .filter((e) => e.correct)
    .forEach((sub) => {
      sub.rank = ++rank;
      sub.rankPoint =
        problem.baseScore[Math.min(rank - 1, problem.baseScore.length - 1)];
      sub.timePoint = (sub.submitTime * problem.scoreTimeCoefficient) / 1000;
      sub.pagePoint = getPagePoint(
        problem.timeLimit - sub.submitTime / 1000,
        problem.problemImageSwitchingTime,
        problem.pagePoint
      );
      sub.totalPoint = sub.rankPoint + sub.timePoint + (sub.pagePoint ?? 0);
      console.log(sub);
    });
  return newSubmits;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const Statistics: FC = (props) => {
  const urlParams = useParams<{
    eventId: string;
    roomId: string;
    probId?: string;
  }>();
  const roomId = useMemo(() => {
    return urlParams.roomId as string;
  }, [urlParams.roomId]);

  const location = useLocation();
  const mode = useMemo(() => {
    return location.pathname.split("/")[1];
  }, [location]);

  const eventId = useMemo(() => {
    return urlParams.eventId as string;
  }, [urlParams.eventId]);
  const [value, setValue] = useState<number>(
    parseInt(urlParams.probId ?? (mode === "archives" ? "1" : "0"))
  );
  const [problems, setProblems] = useState<Problem[]>([]);
  const [submits, setSubmits] = useState<Stats[][]>([]);

  useEffect(() => {
    const load = async () => {
      if (!roomId) {
        return;
      }
      const roomDoc = await getDoc(doc(db, "v2rooms", roomId));
      const roomData = roomDoc.data() as V2Room;
      const setsDoc = await getDoc(doc(db, "sets", roomData.setId));
      const setData = setsDoc.data();
      const problems = setData?.problems as RawProblem[];
      const probSetId = roomData.setId;
      return problems.map((problem) =>
        convertRawProblemToProblem(problem, probSetId)
      );
    };
    load().then((prob) => {
      console.log(prob);
      if (prob) {
        setProblems(prob);
        if (value === -1) {
          setValue(prob.length);
        }
      }
    });
    setSubmits([]);
    setValue(parseInt(urlParams.probId ?? (mode === "archives" ? "1" : "0")));
    console.log(problems);
  }, [urlParams]);

  useEffect(() => {
    if (!problems) {
      return;
    }
    if (value === 0) {
      return;
    }
    const submitValue = value - 1;
    const problem = problems[submitValue];
    if (!problem) {
      return;
    }

    if (!submits[submitValue]) {
      loadSubmits(roomId, problem.problemId).then((submit) => {
        if (!submit) {
          return;
        }
        setSubmits((prev) => {
          return toNewSubmits(prev, submit, problem, submitValue);
        });
      });
    }
  }, [value, problems]);

  useEffect(() => {
    if (!problems) {
      return;
    }
    if (problems.length === 0) {
      return;
    }
    if (value !== 0) {
      return;
    }
    for (let i = 0; i < problems.length; i++) {
      const problem = problems[i];
      if (!problem) {
        return;
      }
      if (!submits[i]) {
        loadSubmits(roomId, problem.problemId).then((submit) => {
          if (!submit) {
            return;
          }
          setSubmits((prev) => {
            return toNewSubmits(prev, submit, problem, i);
          });
        });
      }
    }
  }, [value, problems]);

  const totalStat = useMemo(() => {
    if (!submits) {
      return;
    }
    if (submits.length === 0) {
      return;
    }
    if (
      problems.some((e, i) => {
        return !submits[i];
      })
    ) {
      return;
    }

    const statWithName: { [key: string]: number[] } = {};
    const totalStatWithName: { [key: string]: number } = {};

    problems.forEach((prob, i) => {
      const submit = submits[i];
      submit.forEach((sub) => {
        if (!statWithName[sub.name]) {
          statWithName[sub.name] = [];
        }
        statWithName[sub.name][i] = sub.totalPoint;
      });
    });

    Object.keys(statWithName).forEach((name) => {
      const stat = statWithName[name];
      totalStatWithName[name] = stat.reduce((a, b) => a + b);
    });
    const rankName = Object.keys(totalStatWithName).sort(
      (a, b) => totalStatWithName[b] - totalStatWithName[a]
    );
    return {
      statWithName,
      totalStatWithName,
      rankName,
    };
  }, [submits, problems]);

  const [cardPos, setCardPos] = useState<number>(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setCardPos(0);
    navigate(`/${mode}/${urlParams.eventId}/${urlParams.roomId}/${newValue}`);
  };

  const [drawerOpen, setDrawerOpen] = useStatePersist(
    "stats-drawerOpen",
    false
  );
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  const theme = useTheme();
  const navigate = useNavigate();

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (!newAlignment) {
      return;
    }
    navigate(
      `/${newAlignment}/${urlParams.eventId}/${urlParams.roomId}/${value}`
    );
  };

  const room = useMemo(() => {
    return eventData?.[eventId].rooms.find((r) => r.roomId === roomId);
  }, [eventId, roomId]);

  const probCard = useMemo(() => {
    if (!problems) {
      return;
    }
    const prob = problems[value - 1];
    if (!prob) {
      return;
    }
    const beforeImgUrl = prob.beforeImgUrl;
    const imgUrl = prob.imgUrl;
    const answerImgUrl = prob.answerImgUrl;
    return [
      ...(beforeImgUrl && beforeImgUrl.length > 0
        ? beforeImgUrl.map((url) => ({ type: "img", url }))
        : [
            {
              type: "text",
              text: `${value}問目`,
            },
          ]),
      ...(imgUrl ? imgUrl.map((url, i) => ({ type: "img", url })) : []),
      {
        type: "text",
        text: "解説",
      },
      ...(answerImgUrl && answerImgUrl.length > 0
        ? answerImgUrl.map((url, i) => ({ type: "img", url, isAnswer: true }))
        : [
            {
              type: "text",
              text: "A." + prob.answers[0],
              isAnswer: true,
            },
          ]),
    ] as (
      | {
          type: "img";
          url: string;
          isAnswer?: boolean;
        }
      | {
          type: "text";
          text: string;
          isAnswer?: boolean;
        }
    )[];
  }, [problems, value]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={drawerOpen}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" color="inherit" component="div">
            EnigmaArena{" "}
            {mode === "archives" ? "Archives" : mode === "stats" ? "Stats" : ""}
          </Typography>
          <ToggleButtonGroup
            value={mode}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            sx={{
              mr: 2,
              ml: "auto",
            }}
          >
            <ToggleButton value="archives" aria-label="present">
              <FilePresentIcon />
            </ToggleButton>
            <ToggleButton value="stats" aria-label="graph">
              <InsightsIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {eventData[eventId]?.rooms.map((room) => (
            <ListItemButton
              key={room.roomId}
              onClick={() => {
                setCardPos(0);
                navigate(
                  `/${mode}/${urlParams.eventId}/${room.roomId}/${
                    mode === "archives" ? "1" : "0"
                  }`
                );
              }}
            >
              <ListItemIcon>
                {mode === "archives" ? (
                  <FilePresentIcon />
                ) : mode === "stats" ? (
                  <InsightsIcon />
                ) : (
                  <></>
                )}
              </ListItemIcon>
              <ListItemText primary={room.name} />
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <Main open={drawerOpen}>
        <DrawerHeader />
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="text.primary">
            {eventData[eventId].title}
          </Typography>
          <Typography color="text.primary">{room?.name ?? ""}</Typography>
        </Breadcrumbs>
        {problems && (
          <Box
            sx={{
              maxWidth: "100vw",
              bgcolor: "background.paper",
              ml: 0,
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab key={0} label={"最終結果"} {...a11yProps(0)} value={0} />
                {problems.map((prob, i) => (
                  <Tab
                    key={i + 1}
                    label={prob.problemId}
                    value={i + 1}
                    {...a11yProps(i + 1)}
                    sx={{
                      minWidth: 50,
                      maxWidth: 50,
                    }}
                  />
                ))}
              </Tabs>
            </Box>
            {problems.map((prob, i) => (
              <CustomTabPanel value={value} index={i + 1} key={i + 1}>
                {mode === "stats" && submits[value - 1] && (
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 700, maxWidth: 900 }}
                      aria-label="customized table"
                    >
                      <TableHead>
                        <TableRow>
                          <StyledTableCell sx={{ width: 20 }}>
                            正解
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: 20 }}>
                            順位
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: 120 }}>
                            名前
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: 180 }}>
                            解答
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: 60 }}>
                            提出時間(秒)
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: 60 }}>
                            基礎点・順位点
                          </StyledTableCell>
                          <StyledTableCell sx={{ width: 60 }}>
                            タイムボーナス
                          </StyledTableCell>
                          {problems.some((p) => p.pagePoint) && (
                            <StyledTableCell sx={{ width: 60 }}>
                              ページ得点
                            </StyledTableCell>
                          )}
                          <StyledTableCell sx={{ width: 60 }}>
                            得点
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {submits[value - 1].map((row, i) => (
                          <StyledTableRow key={i}>
                            <StyledTableCell
                              align="left"
                              sx={{
                                color: row.correct
                                  ? "success.main"
                                  : "error.main",
                              }}
                            >
                              {row.correct ? "○" : "×"}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {row.rank > 0 ? row.rank : ""}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{
                                color: row.correct
                                  ? "success.main"
                                  : "error.main",
                              }}
                            >
                              {row.answer}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.answer !== ""
                                ? (row.submitTime / 1000).toFixed(3)
                                : ""}
                            </StyledTableCell>
                            <StyledTableCell>{row.rankPoint}</StyledTableCell>
                            <StyledTableCell>
                              {row.timePoint === 0
                                ? row.timePoint
                                : row.timePoint.toFixed(3)}
                            </StyledTableCell>
                            {(row.pagePoint ?? null) !== null && (
                              <StyledTableCell sx={{ width: 60 }}>
                                {row.pagePoint}
                              </StyledTableCell>
                            )}
                            <StyledTableCell>
                              {row.timePoint === 0
                                ? row.totalPoint
                                : row.totalPoint.toFixed(3)}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {mode === "archives" && (
                  <>
                    {/**problem info text */}
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        alignItems: "left",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          textAlign: "center",
                          mt: 2,
                        }}
                      >
                        制限時間: {prob.timeLimit}秒
                      </Typography>
                    </Grid>

                    {probCard && probCard.length > 0 && (
                      <>
                        <Grid
                          container
                          sx={{
                            alignItems: "left",
                            mt: 4,
                          }}
                        >
                          <Card
                            sx={{
                              width: "100vw",
                              maxWidth: 640,
                            }}
                            key={i}
                          >
                            {probCard[cardPos].type === "img" && (
                              <CardMedia
                                component="img"
                                sx={{
                                  mx: "auto",
                                  my: "auto",
                                  width: "100%",
                                  objectFit: "contain",
                                }}
                                image={
                                  imageRoot +
                                  (
                                    probCard[cardPos] as {
                                      type: "img";
                                      url: string;
                                    }
                                  ).url
                                }
                              />
                            )}
                            {probCard[cardPos].type === "text" && (
                              <CardContent
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "52vw",
                                  maxWidth: 640,
                                  maxHeight: 360,
                                }}
                              >
                                <Typography
                                  variant="h1"
                                  sx={{
                                    textAlign: "center",
                                    my: "auto",
                                    userSelect: "none",
                                  }}
                                >
                                  {
                                    (
                                      probCard[cardPos] as {
                                        type: "text";
                                        text: string;
                                      }
                                    ).text
                                  }
                                </Typography>
                              </CardContent>
                            )}

                            <CardActions
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <IconButton
                                sx={{ ml: 2, mr: 2 }}
                                onClick={() => {
                                  //prev page
                                  navigate(
                                    `/${mode}/${urlParams.eventId}/${
                                      urlParams.roomId
                                    }/${value - 1}`
                                  );
                                  setCardPos(0);
                                }}
                                disabled={value === 1}
                              >
                                <SkipPreviousIcon />
                              </IconButton>
                              <Button
                                size="medium"
                                variant="contained"
                                sx={{
                                  ml: 2,
                                  mr: "auto",
                                }}
                                disabled={cardPos === 0}
                                onClick={() => {
                                  setCardPos((prev) => prev - 1);
                                }}
                              >
                                前へ
                              </Button>
                              {/* right button */}
                              <Button
                                size="medium"
                                variant="contained"
                                sx={{
                                  ml: "auto",
                                  mr: 2,
                                }}
                                disabled={cardPos === probCard.length - 1}
                                onClick={() => {
                                  setCardPos((prev) => prev + 1);
                                }}
                              >
                                次へ
                              </Button>
                              <IconButton
                                sx={{ ml: 2, mr: 2 }}
                                disabled={value === problems.length}
                                onClick={() => {
                                  navigate(
                                    `/${mode}/${urlParams.eventId}/${
                                      urlParams.roomId
                                    }/${value + 1}`
                                  );
                                  setCardPos(0);
                                }}
                              >
                                <SkipNextIcon />
                              </IconButton>
                            </CardActions>
                          </Card>
                        </Grid>
                        <Grid>
                          {probCard[cardPos].isAnswer && (
                            <Typography
                              variant="h5"
                              sx={{
                                textAlign: "left",
                                mt: 2,
                              }}
                            >
                              表記揺れ: {problems[value - 1].answers.join(", ")}
                            </Typography>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </CustomTabPanel>
            ))}
            <CustomTabPanel value={value} index={0} key={0}>
              {totalStat && (
                <TableContainer component={Paper}>
                  <Table
                    sx={{
                      minWidth: mode === "stats" ? 700 : 280,
                      maxWidth:
                        280 + (mode === "stats" ? problems.length * 100 : 0),
                    }}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell sx={{ width: 20 }}>
                          順位
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: 200 }}>
                          名前
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: 60 }}>
                          最終得点
                        </StyledTableCell>
                        {mode === "stats" &&
                          problems.map((prob, i) => (
                            <StyledTableCell sx={{ width: 100 }} key={i}>
                              {prob.problemId}問目
                            </StyledTableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {totalStat.rankName.map((name, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell align="left">
                            {i + 1}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {room?.totalScoreType === "int"
                              ? totalStat.totalStatWithName[name].toFixed(0)
                              : totalStat.totalStatWithName[name].toFixed(3)}
                          </StyledTableCell>
                          {mode === "stats" &&
                            problems.map((_, i) => (
                              <StyledTableCell
                                sx={{
                                  width: 60,
                                  color:
                                    totalStat.statWithName[name][i] === 0
                                      ? "text.secondary"
                                      : "text.primary",
                                  fontWeight:
                                    totalStat.statWithName[name][i] ===
                                    submits[i].find((e) => e.rank === 1)
                                      ?.totalPoint
                                      ? "bold"
                                      : "normal",
                                }}
                                key={i}
                              >
                                {(totalStat.statWithName[name][i] ?? 0) === 0
                                  ? 0
                                  : room?.totalScoreType === "int"
                                  ? totalStat.statWithName[name][i].toFixed(0)
                                  : totalStat.statWithName[name][i].toFixed(3)}
                              </StyledTableCell>
                            ))}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </CustomTabPanel>
          </Box>
        )}
      </Main>
    </Box>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
