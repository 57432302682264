import {
  collection,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";

export const listenAllUserHash = (
  roomId: string,
  setAllUserHash: (g: any[]) => void
) => {
  const db = getFirestore();
  const colRef = collection(db, "v2rooms", roomId, "hash");
  //let success = false;
  const stopUserHashSnapshot = onSnapshot(colRef, (col) => {
    const allUserHash = col.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setAllUserHash(allUserHash);
  });

  return stopUserHashSnapshot;
};
