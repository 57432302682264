import "../../App.css";

import { get } from "http";

import { Button } from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
// App.tsx
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { db } from "../..";
import { events } from "./data/1st";

const Setter: React.FC = () => {
  const [elapsedTime, setElapsedTime] = useState<number>(0); // Update this line
  const [startDateTime, setStartDateTime] = useState<number>(
    new Date().getTime()
  );
  const [problemStartDateTime, setProblemStartDateTime] = useState<number>(
    new Date().getTime()
  );
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>("");
  const [isGameOver, setIsGameOver] = useState<boolean>(false);
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const { eventId } = useParams<"eventId">();
  const eventInfo = useMemo(() => {
    return events[eventId ?? "sample"];
  }, [eventId]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === " ") {
        if (!isGameStarted) {
          startGame();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isGameStarted]);

  useEffect(() => {
    if (!eventInfo) {
      return;
    }
    eventInfo.questions.forEach((question) => {
      const img = new Image();
      img.src = question.image; // プリロードする
    });
  }, [eventInfo]);

  useEffect(() => {
    if (!isGameStarted) return;
    if (isGameOver) {
      return () => clearInterval(timer);
    }

    const timer = setInterval(() => {
      if (isGameOver) return;
      setElapsedTime(
        (prevTime) => (new Date().getTime() - startDateTime) / 1000
      );
    }, 100);

    return () => clearInterval(timer);
  }, [isGameStarted, isGameOver]);

  const startGame = () => {
    setStartDateTime(new Date().getTime());
    setProblemStartDateTime(new Date().getTime());
    setInputValue("");
    setIsGameStarted(true);
  };
  const endGame = () => {
    setElapsedTime((prevTime) => (new Date().getTime() - startDateTime) / 1000);
    setIsGameOver(true);
  };

  const [isIME, setIsIME] = useState<boolean>(false);

  const submit = (value: string) => {
    if (eventInfo.questions[currentQuestion].answers.includes(value)) {
      setCurrentQuestion((prevQuestion) => {
        setProblemStartDateTime(new Date().getTime());
        if (prevQuestion + 1 >= eventInfo.questions.length) {
          endGame();
          return prevQuestion;
        }
        return prevQuestion + 1;
      });
      setInputValue("");
    }
  };
  const onCompositionStart = () => {
    setIsIME(true);
  };
  const onCompositionEnd = () => {
    setIsIME(false);
    submit(inputValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === " ") {
      setInputValue("");
    } else {
      setInputValue(value);
    }

    if (!isIME) {
      submit(value);
    }
  };

  const submitScore = async (event: any) => {
    if (!eventId) {
      return;
    }

    const name = prompt("名前を入力してください");
    if (!name) {
      return;
    }
    const data = {
      name: name,
      score: elapsedTime,
    };

    const ref = doc(db, "race", eventId, "user", name);
    const docSnap = await getDoc(ref);
    if (!docSnap.exists()) {
      await setDoc(ref, data);
      alert("記録を送信しました");
    }
  };

  if (!isGameStarted) {
    return (
      <div className="App">
        <h1>{eventInfo.title}</h1>
        {eventInfo.descriptions}
        <button className="start-button" onClick={startGame}>
          スタート
        </button>
        <p>スペースキーでもスタートできます。</p>
      </div>
    );
  }

  if (isGameOver) {
    return (
      <div className="App">
        <h1>{eventInfo.title}</h1>
        <p className="result-time">タイム: {elapsedTime.toFixed(2)}s</p>
        <Button
          sx={{
            my: 10,
          }}
          onClick={submitScore}
          variant={"contained"}
        >
          記録を送信
        </Button>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="timer">{elapsedTime.toFixed(1)}s</div>
      <div className="question">
        <img src={eventInfo.questions[currentQuestion].image} alt="問題画像" />
      </div>
      <br className="spacer" />
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onCompositionStart={onCompositionStart}
        onCompositionEnd={onCompositionEnd}
        className="answer"
        autoFocus
      />
      {(new Date().getTime() - problemStartDateTime) / 1000 > 30 && (
        <p>ヒント: {eventInfo.questions[currentQuestion].hint}</p>
      )}
    </div>
  );
};

export default Setter;
