import { doc, getDoc, onSnapshot } from "firebase/firestore";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { db } from "../../../index";

export const V2MyPage: React.FC = (props) => {
  const [roomId, setRoomId] = React.useState<string>("");
  const [playerName, setPlayerName] = React.useState<string>("");
  const [hash, setHash] = React.useState<string>("");
  const location = useLocation();

  React.useEffect(() => {
    setRoomId(urlParams.roomId ? urlParams.roomId : "");
    setHash(urlParams.hash ? urlParams.hash : "");
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        const hashDoc = await getDoc(doc(db, "v2rooms", roomId, "hash", hash));
        setPlayerName(hashDoc.data()?.name ? hashDoc.data()?.name : "");
      } catch (e) {
        console.log(e);
      }
    })();
  }, [db, roomId, hash]);

  const intoRoom = () => {
    navigate("/v2room/" + roomId + "/" + hash + "/game");
  };

  const showRanking = () => {
    navigate("/v2room/" + roomId + "/" + hash + "/ranking");
  };

  const urlParams = useParams<{ roomId: string; hash: string }>();
  const navigate = useNavigate();
  const goTo = (url: string) => {
    navigate(url);
  };

  return (
    <>
      <p>Mypage</p>
      <br />
      <p>roomId：{roomId}</p>
      <p>playerName：{playerName}</p>
      <p>メニュー</p>
      <button onClick={intoRoom}>ゲーム画面へ</button>
      <button onClick={showRanking}>ランキング表示</button>
    </>
  );
};