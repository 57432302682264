import { Box, Container } from "@mui/material";

import image from "../static/title/EnigmaArena.001.png";

export const Title: React.FC = () => {
  return (
    <Box
      component={"div"}
      sx={{
        width: "100vw",
        height: "100vh",
        bgcolor: "#b51800",
        mx: 0,
        my: 0,
        px: 0,
        py: 0,
      }}
    >
      <Box
        component="img"
        sx={{
          width: "100vw",
          px: 0,
          py: 0,
          mx: "auto",
          my: "auto",
        }}
        alt="Title"
        src={image}
      />
    </Box>
  );
};
