import { doc, onSnapshot, setDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { db } from "..";

type DocArg<T> = {
  path?: string | null;
  initialData?: T;
};

export const useDoc = <T,>({
  path,
  initialData,
}: DocArg<T>): [T | undefined, (data: T) => void, boolean] => {
  const [data, setLocalData] = useState<T>(initialData as T);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!db) {
      return;
    }
    if (!path) {
      return;
    }
    const unsub = onSnapshot(doc(db, path), (docSnapshot) => {
      if (!docSnapshot.exists()) {
        if (initialData) {
          setDoc(doc(db, path), initialData);
        }
        setLoading(false);
        return;
      }
      setLoading(false);
      setLocalData({ ...docSnapshot.data() } as T);
    });
    return unsub;
  }, [db, path]);

  const setData = useCallback(
    (data: T) => {
      if (!db) {
        return;
      }
      if (!path) {
        return;
      }
      setDoc(doc(db, path), data as any);
    },
    [db, path]
  );

  return [data, setData, loading];
};
