import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FieldValue,
  Timestamp,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useDoc } from "../../../hooks/useDoc";
import { RawTowerProblem } from "../../v2arena/admin/AdminSetEdit";
import { BrainChaseUser } from "../player/BrainChaseGame";
import { AdminSorterSelector } from "./component/AdminSorterSelector";
import { useState } from "react";
import { AdminUserTableRow } from "./component/AdminUserTableRow";

export type BrainChaseRoom = {
  setId: string;
  startTime?: Timestamp | FieldValue;
  mode: string;
};

export type BrainChaseSet = {
  type: "brainchase";
  problems: RawTowerProblem[];
  classes: { class: string; time: string }[];
  config: {
    [key: string]: string;
  };
};

const modeNames: {
  [key: string]: string;
} = {
  waiting: "待機中",
  playing: "プレイ中",
  end: "終了",
};

export const AdminBrainChaseRoomManager: React.FC = () => {
  const navigate = useNavigate();
  const { roomId } = useParams<{ roomId: string }>();
  const [room, setRoom] = useDoc<BrainChaseRoom>({
    path: "brainchase/" + roomId,
    initialData: {
      mode: "waiting",
      setId: "default",
    },
  });

  const [roomSet] = useDoc<BrainChaseSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const { docList: users } = useCollection<BrainChaseUser>({
    path: roomId ? "brainchase/" + roomId + "/user" : null,
  });

  const [sorter, setSorter] = useState("name");

  const handleSorterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSorter((event.target as HTMLInputElement).value);
  };

  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");
  const sorterTable: {
    [key: string]: (a: BrainChaseUser, b: BrainChaseUser) => number;
  } = {
    name: (a: BrainChaseUser, b: BrainChaseUser) => {
      return a.name.localeCompare(b.name);
    },
    rank: (a: BrainChaseUser, b: BrainChaseUser) => {
      return (a.rank ?? 0) - (b.rank ?? 0);
    },
  };

  if (!roomId) {
    navigate("/admin");
    return <></>;
  }

  return (
    <Container>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link
          underline="hover"
          key="2"
          color="inherit"
          onClick={() => {
            navigate("/admin");
          }}
        >
          Admin
        </Link>
        <Link
          underline="hover"
          key="2"
          color="inherit"
          onClick={() => {
            navigate("/admin/brainchase");
          }}
        >
          Room
        </Link>
        <Typography key="3" color="text.primary">
          {roomId}
        </Typography>
      </Breadcrumbs>
      <h1>BrainChase RoomManager</h1>
      <h2>プレイ情報</h2>
      <p>ルームID: {roomId}</p>
      {room && (
        <>
          <p>モード: {modeNames[room.mode]}</p>
        </>
      )}
      <Box sx={{ my: 2 }} />
      {roomSet && (
        <>
          <h2>セット情報</h2>
          <p>問題数: {roomSet.problems.length}</p>
          <p>クラス数: {roomSet.classes.length}</p>
        </>
      )}
      <Box sx={{ my: 2 }} />
      <h2>コントローラー</h2>
      {room && room.mode === "waiting" && (
        <Button
          onClick={() => {
            setRoom({
              ...room,
              startTime: serverTimestamp(),
              mode: "playing",
            });
          }}
          variant="contained"
          sx={{ my: 1, mx: 1 }}
        >
          開始
        </Button>
      )}
      {room && (
        <Button
          onClick={() => {
            setRoom({
              ...room,
              mode: "end",
            });
            users
              .map((doc, i) => {
                const r = doc.data();
                return {
                  ref: doc.ref,
                  name: r.name,
                  currentProblem: r.currentProblem ?? 0,
                  clearTime:
                    roomSet &&
                    r.problem?.[roomSet?.problems.length - 1]?.time &&
                    (room?.startTime as Timestamp)
                      ? r.problem?.[roomSet?.problems.length - 1]?.time
                          .toDate()
                          .getTime() /
                          1000 -
                          (room?.startTime as Timestamp)?.toDate().getTime() /
                            1000 -
                          defaultOffsetTime ?? null
                      : null,
                  lastTime:
                    r.currentProblem > 0
                      ? r.problem?.[r.currentProblem - 1]?.time
                          .toDate()
                          .getTime() /
                          1000 -
                        (room?.startTime as Timestamp)?.toDate().getTime() /
                          1000
                      : 99999999,
                };
              })
              .sort((a, b) => {
                if (a.clearTime && b.clearTime) {
                  return a.clearTime - b.clearTime;
                }
                if (a.currentProblem === b.currentProblem) {
                  return a.lastTime - b.lastTime;
                }
                return b.currentProblem - a.currentProblem;
              })
              .forEach((r, i) => {
                const ref = r.ref;
                console.log(r, i + 1);
                setDoc(ref, { rank: i + 1 }, { merge: true });
              });
          }}
          variant="contained"
          sx={{ my: 1, mx: 1 }}
        >
          終了
        </Button>
      )}
      <h2>ユーザー情報</h2>
      <AdminSorterSelector sorter={sorter} onChange={handleSorterChange} />
      <Box sx={{ my: 2 }}>
        <Table
          style={{
            borderCollapse: "collapse",
            borderSpacing: 0,
            textAlign: "left",
            fontSize: "1.2rem",
            border: "1px solid #333",
          }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>名前</TableCell>
              <TableCell
                sx={{
                  width: "10%",
                  minWidth: "120px",
                }}
              >
                編集
              </TableCell>
              <TableCell>クラス</TableCell>
              <TableCell>問題数</TableCell>
              <TableCell>クリア時間</TableCell>
              <TableCell>最終順位</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users &&
              users
                .sort((a, b) => {
                  return sorterTable[sorter](
                    a.data() as BrainChaseUser,
                    b.data() as BrainChaseUser
                  );
                })
                .map((user, i) => {
                  return (
                    <AdminUserTableRow
                      key={i}
                      user={user.data() as BrainChaseUser}
                      userRef={user.ref}
                      roomSet={roomSet ?? null}
                      room={room ?? null}
                    />
                  );
                })}
          </TableBody>
        </Table>
      </Box>
    </Container>
  );
};
