import { Button, Container, TextField, Typography } from "@mui/material";
import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import React from "react";
import { hashString } from "react-hash-string";
import Modal from "react-modal";
import { Link, useNavigate, useParams } from "react-router-dom";

import { db } from "../../index";

const SiritoriRoomIn: React.FC = (props) => {
  const [playerName, setPlayerName] = React.useState<string>("");
  const [roomId, setRoomId] = React.useState<string>("myroom");
  const [modalMessage, setModalMessage] = React.useState<string>("");
  const navigate = useNavigate();
  const urlParams = useParams<{ roomId: string }>();

  React.useEffect(() => {
    if (urlParams.roomId) setRoomId(urlParams.roomId);
  }, []);

  const intoRoom = () => {
    if (playerName === "") return;
    const hash = makeHash(playerName);
    (async () => {
      const roomDocSnap = await getDoc(doc(db, "siritoriRooms", roomId));
      if (!roomDocSnap.exists()) {
        setModalMessage("存在しないルームIDです");
        return;
      }
      const hashDocSnap = await getDoc(
        doc(db, "siritoriRooms", roomId, "hash", hash)
      );
      if (hashDocSnap.exists()) {
        if (hashDocSnap.data()?.name === playerName)
          navigate("/word/" + roomId + "/" + hash + "/game");
        else {
          setModalMessage("その名前は使用できません");
          return;
        }
      }
      setDoc(doc(db, "siritoriRooms", roomId, "hash", hash), {
        name: playerName,
      });
      navigate("/word/" + roomId + "/" + hash + "/game");
    })();
  };

  const makeHash = (str: string) => {
    let ret = "";
    ret += String(Math.abs(hashString(playerName)));
    while (ret.length < 10) ret += "0";
    let EmanReyalp = playerName;
    EmanReyalp = EmanReyalp.split("").reverse().join("");
    ret += String(Math.abs(hashString(EmanReyalp)));
    while (ret.length < 20) ret += "0";
    return ret;
  };

  let subtitle: HTMLHeadingElement | null;

  function afterOpenModal() {
    if (subtitle) subtitle.style.color = "#f00";
  }

  function closeModal() {
    setModalMessage("");
  }

  return (
    <Container sx={{ textAlign: "center" }}>
      <Typography variant="h3" sx={{ margin: "auto", my: 2 }}>
        入室
      </Typography>
      <br />
      <text>ID：{roomId}</text>
      <br />
      <TextField
        label="名前(12文字以内)"
        type="text"
        value={playerName}
        onKeyPress={(e: any) => {
          if (e.key == "Enter") {
            intoRoom();
          }
        }}
        onChange={(e: any) => {
          setPlayerName(e.target.value);
        }}
        sx={{ mx: "auto", my: 5 }}
        inputProps={{ maxLength: 12 }}
      />
      <br />
      <Button
        onClick={intoRoom}
        variant={"contained"}
        size={"large"}
        sx={{ mx: "auto", my: 5 }}
      >
        入室
      </Button>
      <Modal
        contentLabel="Example Modal"
        isOpen={modalMessage !== ""}
        style={customStyles}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{modalMessage}</h2>
        <button onClick={closeModal}>OK</button>
      </Modal>
    </Container>
  );
};

export default SiritoriRoomIn;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
