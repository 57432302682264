import { doc, getDoc } from "firebase/firestore";
import React, { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { db } from "../../index";

const MyPage: FC = () => {
  const [roomId, setRoomId] = React.useState<string>("");
  const [playerName, setPlayerName] = React.useState<string>("");
  const [hash, setHash] = React.useState<string>("");

  useEffect(() => {
    setRoomId(urlParams.roomId ? urlParams.roomId : "");
    setHash(urlParams.hash ? urlParams.hash : "");
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const hashDoc = await getDoc(doc(db, "v2rooms", roomId, "hash", hash));
        setPlayerName(hashDoc.data()?.name ? hashDoc.data()?.name : "");
      } catch (e) {
        console.log(e);
      }
    })();
  }, [db, roomId, hash]);

  const intoRoom = () => {
    navigate("/room/" + roomId + "/" + hash + "/game");
  };

  const showRanking = () => {
    navigate("/room/" + roomId + "/" + hash + "/ranking");
  };

  const urlParams = useParams<{ roomId: string; hash: string }>();
  const navigate = useNavigate();

  return (
    <>
      <p>Mypage</p>
      <br />
      <p>roomId：{roomId}</p>
      <p>playerName：{playerName}</p>
      <p>メニュー</p>
      <button onClick={intoRoom}>ゲーム画面へ</button>
      <button onClick={showRanking}>ランキング表示</button>
    </>
  );
};

export default MyPage;
