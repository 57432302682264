import { Face as FaceIcon, Home as HomeIcon } from "@mui/icons-material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  TextField,
  Toolbar,
} from "@mui/material";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { db } from "../../index";
import { getServerTime } from "../../lib/getServerTime";
import { ScreenResult } from "../admin/component/ScreenResult";
import { ScreenResultRanking } from "../admin/component/ScreenResultRanking";
import { DisplayExplanation } from "./component/DisplayExplanation";
import { DisplayProblem } from "./component/DisplayProblem";

const AnswerForm: React.FC<{
  setPlayerAnswer: React.Dispatch<React.SetStateAction<string>>;
  playerAnswer: string;
  remainingSubmitCount: number;
  inputType: string;
  displayMode: string;
  finish: boolean;
  check: any;
  choices: string[];
}> = (props: any) => {
  const listData = props.choices.map((str: any) => (
    <li key={str}>
      <input
        disabled={props.finish || props.remainingSubmitCount === 0}
        type="checkbox"
        checked={props.playerAnswer === str}
        onChange={(e: any) => {
          props.setPlayerAnswer(str);
          console.log(props.finish);
        }}
      />
      {str}
    </li>
  ));

  console.log(props.displayMode, props.inputType);
  if (props.displayMode !== "prob") {
    return <></>;
  }

  if (props.inputType === "free") {
    return (
      <>
        <Grid container direction="column" alignItems="center">
          <TextField
            variant="outlined"
            value={props.playerAnswer}
            disabled={props.finish || props.remainingSubmitCount === 0}
            onChange={(e: any) => {
              if (e.target.value.length > 30) return; // 最大文字数
              props.setPlayerAnswer(e.target.value);
            }}
            onKeyPress={(e: any) => {
              if (e.key == "Enter") props.check();
            }}
            sx={{ my: 1 }}
          />
          <Button
            disabled={props.finish || props.remainingSubmitCount === 0}
            onClick={props.check}
            variant="contained"
            sx={{ my: 1 }}
          >
            提出
          </Button>
          <p>
            残り提出：
            {props.remainingSubmitCount >= 100010
              ? "∞"
              : props.remainingSubmitCount}
            回
          </p>
        </Grid>
      </>
    );
  }

  if (props.inputType === "choice") {
    return (
      <>
        <div>
          <ul>{listData ? listData : []}</ul>
          <Button
            disabled={props.finish || props.remainingSubmitCount === 0}
            onClick={props.check}
            variant="contained"
          >
            提出
          </Button>
        </div>
        <p>
          残り提出：
          {props.remainingSubmitCount >= 100010
            ? "∞"
            : props.remainingSubmitCount}
          回
        </p>
      </>
    );
  }

  return <></>;
};

let unsub = () => {
  return;
};

const Game: React.FC = (props) => {
  const [roomId, setRoomId] = React.useState<string>("");
  const [eventId, setEventId] = React.useState<string>("");
  const [displayMode, setDisplayMode] = React.useState<string>("");
  const [currentProblemId, setCurrentProblemId] = React.useState<string>("");
  const [problemState, setProblemState] = React.useState<string>("");
  const [hash, setHash] = React.useState<string>("");
  const [playerName, setPlayerName] = React.useState<string>("");
  const [inputType, setInputType] = React.useState<string>("");
  const [isCorrect, setIsCorrect] = React.useState<boolean>(false);
  const [baseScore, setBaseScore] = React.useState<number>(0);
  const [scoreTimeCoefficient, setScoreTimeCoefficient] = useState<number>(0);
  const [immediateCheck, setImmediateCheck] = useState<boolean>();
  const [playerAnswer, setPlayerAnswer] = useState<string>("");
  const [answers, setAnswers] = useState<string[]>([]);
  const [timeLimit, setTimeLimit] = useState<number>(0);
  const [remainingTime, setRemainingTime] = React.useState<number>(0);
  const [startTime, setStartTime] = React.useState<number>(0);
  const [sendMessage, setSendMessage] = React.useState<string>("");
  const [choices, setChoices] = React.useState<string[]>([]);
  const [probInd, setProbInd] = React.useState<number>(0);
  const [nowPoint, setNowPoint] = React.useState<number>(0);
  const [remainingSubmitCount, setRemainingSubmitCount] =
    React.useState<number>(0);
  const [finish, setFinish] = React.useState<boolean>(true);
  const [imgUrl, setImgUrl] = React.useState<string>("");
  const [answerImgUrl, setAnswerImgUrl] = React.useState<string>("");
  const urlParams = useParams<{ roomId: string; hash: string }>();

  const setInfo = (roomId: string, roomDoc: any) => {
    (async () => {
      if (eventId === "") {
        return;
      }
      console.log("setInfo");
      const probDoc = await getDoc(
        doc(db, "events", eventId, "problems", roomDoc.data()?.currentProblemId)
      );

      const subDoc = await getDoc(
        doc(db, "rooms", roomId, "submits", roomDoc.data()?.currentProblemId)
      );

      const eventDoc = await getDoc(doc(db, "events", eventId));
      let tmpRemainingSubmitCount = probDoc.data()?.answerCount
        ? probDoc.data()?.answerCount
        : eventDoc.data()?.defaultAnswerCount;
      let tmpCorrect = false;
      const submitDoc = doc(
        db,
        "rooms",
        roomId,
        "submits",
        roomDoc.data()?.currentProblemId,
        "users",
        playerName
      );
      const submitDocSnap = await getDoc(submitDoc);
      if (submitDocSnap.exists() === true) {
        tmpRemainingSubmitCount = submitDocSnap.data()?.remainingSubmitCount;
        tmpCorrect = submitDocSnap.data()?.correct;
      } else {
        console.log("submitCount", tmpRemainingSubmitCount);
        setDoc(submitDoc, {
          answer: "",
          remainingSubmitCount: tmpRemainingSubmitCount,
          submitTime: -1,
          correct: false,
          point: 0,
        });
      }
      const tmpImmediateCheck =
        probDoc.data()?.immediateCheck !== null
          ? probDoc.data()?.immediateCheck
          : eventDoc.data()?.defaultImmediateCheck;
      setStartTime(subDoc.data()?.startTime);
      setRemainingSubmitCount(tmpRemainingSubmitCount);
      setCurrentProblemId(roomDoc.data()?.currentProblemId);
      setDisplayMode(roomDoc.data()?.displayMode);
      setProbInd(roomDoc.data()?.probInd);
      setProblemState(probDoc.data()?.title);
      setPlayerAnswer("");
      setInputType(probDoc.data()?.inputType);
      setAnswers(probDoc.data()?.answers);
      setImmediateCheck(false && tmpImmediateCheck);
      setIsCorrect(submitDocSnap.data()?.correct);
      setBaseScore(
        probDoc.data()?.baseScore
          ? probDoc.data()?.baseScore
          : eventDoc.data()?.defaultBaseScore
      );
      setScoreTimeCoefficient(
        probDoc.data()?.scoreTimeCoefficient
          ? probDoc.data()?.scoreTimeCoefficient
          : eventDoc.data()?.defaultScoreTimeCoefficient
      );
      setChoices(probDoc.data()?.choices);
      setTimeLimit(
        probDoc.data()?.time
          ? probDoc.data()?.time
          : eventDoc.data()?.defaultTime
      );
      let tmpFinish = false;
      if (tmpRemainingSubmitCount === 0) tmpFinish = true;
      if (tmpCorrect) tmpFinish = true;
      if (tmpFinish && tmpImmediateCheck)
        setPlayerAnswer(submitDocSnap.data()?.answer);
      if (!tmpImmediateCheck) tmpFinish = false;
      setFinish(tmpFinish);
      setImgUrl(probDoc.data()?.imageFileName);
      setAnswerImgUrl(probDoc.data()?.answerImageFileName);
      console.log(probDoc.data());
      // const source = roomDoc.metadata.hasPendingWrites ? "Local" : "Server";
      // console.log(source, " data: ", roomDoc.data());
    })();
  };

  const [serverTime, setServerTime] = React.useState<any>();
  const [serverTimeDiff, setServerTimeDiff] = React.useState<number>(0);

  const [start, setStart] = React.useState(false);
  const onStart = React.useCallback(() => {
    setStart(true);
  }, [setStart]);

  useEffect(() => {
    if (!start) {
      return;
    }

    console.log("playerName", playerName);
    getServerTime(playerName !== "" ? playerName ?? "player" : "player").then(
      (serverTime: Date) => {
        setServerTimeDiff(serverTime.getTime() - new Date().getTime());
      }
    );

    const interval = setInterval(() => {
      setServerTime(new Date(new Date().getTime() + (serverTimeDiff ?? 0)));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [start]);

  useEffect(() => {
    const timeDiff: number = (serverTime - startTime) / 1000;
    const hoge: number = Math.min(timeLimit, timeLimit - timeDiff + 1);
    if (hoge <= 0) {
      setFinish(true);
      setRemainingTime(0);
      if (!finish) {
        setNowPoint(0);
      }
    } else {
      setRemainingTime(hoge);
      if (!finish) {
        setNowPoint(Number(baseScore) + Number(scoreTimeCoefficient) * hoge);
      }
    }
  }, [startTime, serverTime]);

  useEffect(() => {
    onStart();
    (async () => {
      const tmpRoomId = urlParams.roomId ? urlParams.roomId : "";
      const tmpHash = urlParams.hash ? urlParams.hash : "";
      const hashDoc = await getDoc(
        doc(db, "rooms", tmpRoomId, "hash", tmpHash)
      );
      const tmpRoomDoc = await getDoc(doc(db, "rooms", tmpRoomId));
      setInfo(tmpRoomId, tmpRoomDoc);
      setEventId(tmpRoomDoc.data()?.eventId);
      setHash(tmpHash);
      setPlayerName(hashDoc.data()?.name ? hashDoc.data()?.name : "");
      setRoomId(tmpRoomId);
    })();
  }, []);

  useEffect(() => {
    if (roomId === "" || eventId === "") {
      return;
    }
    unsub();
    console.log("setSnapshot");
    unsub = onSnapshot(doc(db, "rooms", roomId), (roomDoc) => {
      console.log("update", roomId, roomDoc.data());
      setInfo(roomId, roomDoc);
    });
  }, [roomId, eventId]);

  const check = () => {
    if (roomId === "") {
      return;
    }
    if (currentProblemId === "") {
      return;
    }
    if (playerName === "") {
      return;
    }
    if (playerAnswer === "") return;
    const timestamp = new Date().getTime();
    const remainingTimeFine = timeLimit * 1000 - (timestamp - startTime);
    if (remainingTimeFine < 0) return;
    let ok = false;
    for (let i = 0; i < answers.length; i++) {
      if (answers[i] === playerAnswer) ok = true;
    }
    setDoc(
      doc(
        db,
        "rooms",
        roomId,
        "submits",
        currentProblemId,
        "users",
        playerName
      ),
      {
        answer: playerAnswer,
        remainingSubmitCount: remainingSubmitCount - 1,
        submitTime: remainingTimeFine,
        correct: ok,
        point: nowPoint,
      }
    );
    if (!immediateCheck) {
      setModalType("send");
      setSendMessage("あなたの回答：" + playerAnswer);
    } else if (ok) {
      setModalType("correct");
    } else {
      setModalType("incorrect");
    }
    setRemainingSubmitCount(remainingSubmitCount - 1);
    if (ok && immediateCheck) {
      setFinish(true);
      setIsCorrect(true);
      return;
    }
    if (remainingSubmitCount === 1) {
      setFinish(true);
      return;
    }
    setPlayerAnswer("");
  };

  const WaitProblem = () => {
    return (
      <>
        <p>
          解答可能回数：
          {remainingSubmitCount >= 100010 ? "∞" : remainingSubmitCount}
        </p>
        <Text>第{probInd + 1}問</Text>
      </>
    );
  };

  let subtitle: HTMLHeadingElement | null;
  const [modalType, setModalType] = React.useState<string>("");

  function afterOpenModal() {
    if (subtitle) subtitle.style.color = "#f00";
  }

  function closeModal() {
    setModalType("");
  }

  return (
    <BgCrown>
      <Toolbar>
        <Chip icon={<FaceIcon />} label={playerName} sx={{ mx: 1 }} />
        <Chip icon={<HomeIcon />} label={roomId} sx={{ mx: 1 }} />
        <Chip
          icon={<QuestionMarkIcon />}
          label={`第${probInd + 1}問`}
          sx={{ mx: 1 }}
        />
      </Toolbar>
      <Container
        style={{
          overflow: "scroll",
        }}
      >
        <Box>
          {displayMode === "prob" && (
            <DisplayProblem
              immediateCheck={immediateCheck}
              isCorrect={isCorrect}
              remainingTime={remainingTime}
              timeLimit={timeLimit}
              imgUrl={imgUrl}
              nowPoint={nowPoint}
            />
          )}
          {displayMode === "finishProblem" && <p>解説をお待ちください</p>}
          {(displayMode === "explain" ||
            displayMode === "explain-submission") && (
            <DisplayExplanation
              isCorrect={isCorrect}
              answers={answers}
              imgUrl={answerImgUrl ?? imgUrl}
            />
          )}
          {displayMode === "wait" && <WaitProblem />}
          {displayMode === "url" && <WaitProblem />}
          {displayMode === "ranking" && <WaitProblem />}
          {displayMode === "finish" && (
            <>
              <ScreenResult />
              <ScreenResultRanking roomId={roomId} />
            </>
          )}
          {remainingTime > 0 && (
            <AnswerForm
              inputType={inputType}
              playerAnswer={playerAnswer}
              remainingSubmitCount={remainingSubmitCount}
              setPlayerAnswer={setPlayerAnswer}
              displayMode={displayMode}
              finish={finish}
              check={check}
              choices={choices ? choices : []}
            />
          )}
          <div>
            <Modal
              contentLabel="Example Modal"
              isOpen={modalType === "correct"}
              style={customStyles}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
            >
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                正解！（+{nowPoint} point）
              </h2>
              <button onClick={closeModal}>OK</button>
            </Modal>
          </div>
          <div>
            <Modal
              contentLabel="Example Modal"
              isOpen={modalType === "incorrect"}
              style={customStyles}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
            >
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}>不正解...</h2>
              <button onClick={closeModal}>OK</button>
            </Modal>
          </div>
          <div>
            <Modal
              contentLabel="Example Modal"
              isOpen={modalType === "send"}
              style={customStyles}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
            >
              <h2 ref={(_subtitle) => (subtitle = _subtitle)}>送信しました</h2>
              <p>{sendMessage}</p>
              <button onClick={closeModal}>OK</button>
            </Modal>
          </div>
        </Box>
      </Container>
    </BgCrown>
  );
};

export default Game;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  padding: 1rem 0;
  user-select: none;
`;

const BgCrown = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  min-width: 100vh;
  background-color: #fffffe;
  z-index: -1;
  background-image: linear-gradient(
      45deg,
      rgb(216, 237, 253, 0.4) 25%,
      transparent 25%
    ),
    linear-gradient(315deg, rgb(216, 237, 253, 0.4) 25%, #fffffe 25%);
  background-position: 30px 0, 60px 0, 0 0, 0 0;
  background-size: 60px 60px;
  background-repeat: repeat;
  color: #111;
`;
