import { Typography } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { FC, useMemo } from "react";
import {
  BrainChaseRoom,
  BrainChaseSet,
} from "../admin/AdminBrainChaseRoomManager";

type ScreenTimerProps = {
  room: BrainChaseRoom | null;
  roomSet: BrainChaseSet | null;
  serverTime: Date | null;
};

export const ScreenTimer: FC<ScreenTimerProps> = (props) => {
  const { room, roomSet, serverTime } = props;
  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");

  const elapsedTime = useMemo(
    () =>
      room?.startTime && serverTime
        ? serverTime.getTime() / 1000 -
          (room?.startTime as Timestamp)?.toDate().getTime() / 1000 -
          defaultOffsetTime
        : 0,
    [serverTime]
  );

  const remainTime = useMemo(
    () =>
      room?.startTime && serverTime
        ? Math.ceil(parseInt(roomSet?.config.timeLimit ?? "0") - elapsedTime)
        : 0,
    [serverTime]
  );

  return (
    <Typography
      sx={{
        fontSize: "8rem",
        fontWeight: "bold",
      }}
      variant="h1"
    >
      {remainTime <= 0 ? (
        "TIME UP"
      ) : elapsedTime > 0 ? (
        <>
          <span style={{ fontSize: "4rem" }}>TIME: </span>
          {("" + Math.floor(elapsedTime / 60)).padStart(2, "0")}:
          {("" + (Math.floor(elapsedTime) % 60)).padStart(2, "0")}:
          {"" + Math.floor((elapsedTime * 10) % 10)}
        </>
      ) : (
        "READY"
      )}
    </Typography>
  );
};
