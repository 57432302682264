import React from "react";

const Test: React.FC = (props) => {
  return (
    <>
      <text>This is a pen.</text>
    </>
  );
};

export default Test;
