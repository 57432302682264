import {
  Box,
  Card,
  CardContent,
  CardMedia,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";
import { FC } from "react";
import styled from "styled-components";

type DisplayProblemProps = {
  isCorrect: boolean;
  imgUrl: string;
  answers: string[];
};

export const DisplayExplanation: FC<DisplayProblemProps> = (props) => {
  const { isCorrect, imgUrl, answers } = props;
  const str = isCorrect ? "正解" : "不正解";
  return (
    <ProbWrapper>
      <Typography variant="h3">解説</Typography>
      {imgUrl && (
        <Card sx={{ width: "92vw", height: "69vw" }}>
          <CardMedia>
            <ProblemImg src={imgUrl} alt="Problem" />
          </CardMedia>
          <CardContent>
            {/*
                <Typography variant="h3" color="text.primary">
                  {problemState}
                </Typography>
                   */}
          </CardContent>
        </Card>
      )}
      <Typography variant="h4" sx={{ mt: 2 }}>
        解答
      </Typography>
      <Typography variant="h6">{answers.join(", ")}</Typography>
      <Typography variant="h4" sx={{ mt: 2 }}>
        ステータス
      </Typography>
      <Typography variant="h6">{str}</Typography>
      <br />
    </ProbWrapper>
  );
};

const ProbWrapper = styled.div`
  user-select: none;
`;

const ProblemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
