import { Card, CardMedia, Typography } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";

import { imageRoot } from "../../v2arena";

type DisplayProblemProps = {
  isCorrect: boolean;
  answers: string[];
  imgUrls: string[] | null;
  page: number;
};

export const DisplayExplanation: FC<DisplayProblemProps> = (props) => {
  const { isCorrect, answers, imgUrls, page = 0 } = props;
  const str = isCorrect ? "正解" : "不正解";
  return (
    <ProbWrapper>
      <Typography variant="h3">解説</Typography>
      {imgUrls && imgUrls[page] && imgUrls[page] !== "" && (
        <Card sx={{ width: "92vw", height: "69vw" }}>
          <CardMedia>
            <ProblemImg src={imageRoot + imgUrls[page]} alt="Problem" />
          </CardMedia>
        </Card>
      )}
      <Typography variant="h4" sx={{ mt: 2 }}>
        解答
      </Typography>
      <Typography variant="h6">{answers.join(", ")}</Typography>
      <Typography variant="h4" sx={{ mt: 2 }}>
        ステータス
      </Typography>
      <Typography variant="h6">{str}</Typography>
      <br />
    </ProbWrapper>
  );
};

const ProbWrapper = styled.div`
  user-select: none;
`;

const ProblemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
