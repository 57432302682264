import { Box, Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { FC } from "react";

export const AnswerForm: FC<{
  setPlayerAnswer: React.Dispatch<React.SetStateAction<string>>;
  playerAnswer: string;
  remainingSubmitCount: number;
  inputType: string;
  displayMode: string;
  finish: boolean;
  check: any;
  choices: string[];
}> = (props: any) => {
  if (props.displayMode !== "prob") {
    return <></>;
  }

  if (props.inputType === "自由入力") {
    return (
      <>
        <Grid container direction="column" alignItems="center">
          <TextField
            variant="outlined"
            value={props.playerAnswer}
            disabled={props.finish || props.remainingSubmitCount === 0}
            onChange={(e: any) => {
              if (e.target.value.length > 30) return; // 最大文字数
              props.setPlayerAnswer(e.target.value);
            }}
            onKeyPress={(e: any) => {
              if (e.key == "Enter") props.check();
            }}
            sx={{ my: 1 }}
          />
          <Button
            disabled={props.finish || props.remainingSubmitCount === 0}
            onClick={props.check}
            variant="contained"
            sx={{ my: 1 }}
          >
            提出
          </Button>
          <p>
            残り提出：
            {props.remainingSubmitCount >= 999
              ? "∞"
              : props.remainingSubmitCount}
            回
          </p>
        </Grid>
      </>
    );
  }

  if (props.inputType === "選択肢") {
    return (
      <>
        <Grid container direction="column" alignItems="center">
          <Box>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={props.playerAnswer}
                onChange={(e: any) => {
                  props.setPlayerAnswer(e.target.value);
                  console.log(props.finish);
                }}
              >
                {props.choices.map((str: any) => (
                  <FormControlLabel
                    key={str}
                    value={str}
                    control={<Radio />}
                    label={str}
                    disabled={props.finish || props.remainingSubmitCount === 0}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box>
          <Button
            disabled={props.finish || props.remainingSubmitCount === 0}
            onClick={props.check}
            variant="contained"
            sx={{ my: 1 }}
          >
            提出
          </Button>
          <p>
            残り提出：
            {props.remainingSubmitCount >= 999
              ? "∞"
              : props.remainingSubmitCount}
            回
          </p>
        </Grid>
      </>
    );
  }

  return <></>;
};
