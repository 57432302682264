export const getPagePoint = (
  submitTime: number,
  problemImageSwitchingTimeList: number[] | undefined,
  pagePointList: number[] | undefined
) => {
  if (!pagePointList) {
    return undefined;
  }
  if (
    !problemImageSwitchingTimeList ||
    problemImageSwitchingTimeList.length === 0
  ) {
    return undefined;
  }

  const pagePointSumLength = problemImageSwitchingTimeList.filter(
    (e) => e <= submitTime
  ).length;
  return pagePointList[pagePointSumLength];
};

export const getPagePointFromRaw = (
  submitTime: number,
  problemImageSwitchingTime: string | undefined,
  pagePoint: string | undefined
) => {
  return getPagePoint(
    submitTime,
    problemImageSwitchingTime
      ? problemImageSwitchingTime.split(",").map((e) => parseInt(e))
      : undefined,
    pagePoint ? pagePoint.split(",").map((e) => parseInt(e)) : undefined
  );
};
