import { Card, CardContent, Typography } from "@mui/material";
import QRCode from "qrcode.react";

export const DisplayUrl = ({
  version,
  roomId,
}: {
  version?: string;
  roomId: string;
}) => {
  const url = `https://arena.enigmahouse.jp/${version ?? "room"}/` + roomId;
  return (
    <>
      <Card
        variant="outlined"
        sx={{ mt: 10, mx: "auto", px: "10", width: "500px" }}
      >
        <QRCode value={url} size={500} />
        <CardContent sx={{ mt: 10 }}>
          <Typography variant="h4">{url}</Typography>
        </CardContent>
      </Card>
    </>
  );
};
