import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Button,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import React, { useCallback } from "react";

import { db } from "../../../index";
import { RawProblem } from "../../../type";
import { MultipleSpreadSheetLoader } from "./MultipleSpreadSheetLoader";
import { SpreadSheetLoader } from "./SpreadSheetLoader";

type AdminSetEditProps = {
  setIsWorking: React.Dispatch<React.SetStateAction<boolean>>;
  setId: string;
};

export type RawTowerProblem = {
  number: string;
  image: string;
  description: string;
  answer: string;
};

export const AdminSetEdit: React.FC<AdminSetEditProps> = (props) => {
  const erase = () => {
    deleteDoc(doc(db, "sets", props.setId));
    console.log("削除しました！");
    back();
  };
  const back = () => {
    props.setIsWorking(false);
  };
  // v3
  const fetchSheet = useCallback((value: string[][]) => {
    const problems = value
      .filter((_, i) => i >= 2)
      .map((e) => {
        return {
          problemId: e[0],
          beforeImage: e[1],
          problemImage: e[2],
          answerImage: e[3],
          answerCount: e[4],
          lockCount: e[5],
          rankPoint: e[6],
          time: e[7],
          problemImageSwitchingTime: e[8] ?? "",
          timeCoefficient: e[9],
          answer: e[10],
          inputType: e[11],
          choices: e[12],
          judgeTiming: e[13] ?? "",
          pagePoint: e[14] ?? "",
        } as RawProblem;
      });
    const ref = doc(db, "sets", props.setId);
    setDoc(ref, { problems })
      .then(() => {
        console.log("追加しました！");
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  }, []);
  // brainchase
  const fetchBrainChaseSheet = useCallback(
    (values: { [key: string]: string[][] }) => {
      const problems = values["problems"]
        .filter((_, i) => i >= 1)
        .map((e) => {
          return {
            number: e[0],
            image: e[1],
            description: e[2],
            answer: e[3],
          } as RawTowerProblem;
        });
      const classes = values["class"]
        .filter((_, i) => i >= 1)
        .map((e) => {
          return {
            class: e[0],
            time: e[1],
          };
        });
      const config = values["config"]
        .filter((_, i) => i >= 1)
        .map((e) => {
          return {
            key: e[1],
            value: e[2],
          };
        });
      const configTable = config.reduce((acc, e) => {
        acc[e.key] = e.value;
        return acc;
      }, {} as { [key: string]: string });
      const ref = doc(db, "sets", props.setId);
      setDoc(ref, {
        type: "brainchase",
        problems,
        classes,
        config: configTable,
      })
        .then(() => {
          console.log("追加しました！");
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    []
  );

  return (
    <Container>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Typography key="3" color="text.primary">
          Admin
        </Typography>
        <Typography key="3" color="text.primary">
          Edit
        </Typography>
        <Typography key="3" color="text.primary">
          Set
        </Typography>
        <Typography key="3" color="text.primary">
          {props.setId}
        </Typography>
      </Breadcrumbs>
      <h1>EnigmaArena v3</h1>
      <SpreadSheetLoader id={props.setId} fetchValue={fetchSheet} />
      <h1>BrainChase読み込み</h1>
      <MultipleSpreadSheetLoader
        id={props.setId}
        fetchValues={fetchBrainChaseSheet}
        sheetNames={["problems", "class", "config"]}
      />

      <Stack spacing={2} sx={{ mt: 3 }}>
        <Button
          onClick={erase}
          variant="contained"
          sx={{ my: 1, mt: 3, mx: 1, width: 50 }}
        >
          削除
        </Button>
        <Button
          onClick={back}
          variant="contained"
          sx={{ my: 1, mx: 1, width: 50 }}
        >
          戻る
        </Button>
      </Stack>
    </Container>
  );
};
