// import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from "./auth/Login";
import Admin from "./pages/admin/Admin";
import AdminRoomIn from "./pages/admin/v1/AdminRoomIn";
import { AdminBrainChaseRoomIn } from "./pages/brainChase/admin/AdminBrainChaseRoomIn";
import { AdminBrainChaseRoomManager } from "./pages/brainChase/admin/AdminBrainChaseRoomManager";
import { AdminBrainChaseScreen } from "./pages/brainChase/admin/AdminBrainChaseScreen";
import { BrainChaseGame } from "./pages/brainChase/player/BrainChaseGame";
import { BrainChaseRoomInWithId } from "./pages/brainChase/player/BrainChaseRoomIn";
import { Renda } from "./pages/duel/Renda";
import { AdminNazoClusterQuizGame } from "./pages/nazo-cluster-quiz/admin/AdminNazoClusterQuiz";
import { NazoClusterQuizGame } from "./pages/nazo-cluster-quiz/NazoClusterQuizGame";
import Game from "./pages/player/Game";
import MyPage from "./pages/player/MyPage";
import RoomIn from "./pages/player/RoomIn";
import RoomInWithId from "./pages/player/RoomInWithId";
import Ranking from "./pages/Ranking";
import Setter from "./pages/setter/Setter";
import SiritoriGame from "./pages/siritori/SiritoriGame";
import SiritoriRoomIn from "./pages/siritori/SiritoriRoomInWithId";
import Test from "./pages/Test";
import { Title } from "./pages/Title";
import { AdminSetEnter } from "./pages/v2arena/admin/AdminSetEnter";
import { AdminV2RoomIn } from "./pages/v2arena/admin/AdminV2RoomIn";
import V2RoomManager from "./pages/v2arena/admin/V2RoomManager";
import { V2Screen } from "./pages/v2arena/admin/V2Screen";
import { V2Game } from "./pages/v2arena/player/V2Game";
import { V2MyPage } from "./pages/v2arena/player/V2MyPage";
import V2Ranking from "./pages/v2arena/player/V2Ranking";
import { V2RoomIn } from "./pages/v2arena/player/V2RoomIn";
import { V2RoomInWithId } from "./pages/v2arena/player/V2RoomInWithId";
import { Statistics } from "./pages/v2arena/result/Statistics";
import Store from "./redux/store";
import { NazoClusterQuizScreen } from "./pages/nazo-cluster-quiz/screen/NazoClusterQuizScreen";
import { NazoClusterQuizPlayer } from "./pages/nazo-cluster-quiz/player/NazoClusterQuizPlayer";
import { BrainChaseWrapper } from "./pages/brainChase/BrainChaseWrapper";
import { PlayerSelector } from "./pages/nazo-cluster-quiz/player/PlayerSelector";
import { SequenceScreen } from "./pages/sequence/SequenceScreen";

const theme = createTheme({
  palette: {
    primary: {
      main: "#d32f2f",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const App: React.FC = () => {
  return (
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Title />} />
            <Route path="/start" element={<Login />} />
            <Route path="/test" element={<Test />} />
            <Route path="/room" element={<RoomIn />} />
            <Route path="/room/:roomId" element={<RoomInWithId />} />
            <Route path="/room/:roomId/:hash/mypage" element={<MyPage />} />
            <Route path="/room/:roomId/:hash/game" element={<Game />} />
            <Route path="/room/:roomId/:hash/ranking" element={<Ranking />} />
            <Route path="/v2room" element={<V2RoomIn />} />
            <Route path="/v2room/:roomId" element={<V2RoomInWithId />} />
            <Route path="/v2room/:roomId/:hash/mypage" element={<V2MyPage />} />
            <Route path="/v2room/:roomId/:hash/game" element={<V2Game />} />
            <Route
              path="/v2room/:roomId/:hash/ranking"
              element={<V2Ranking />}
            />
            <Route
              path="/brainchase/:roomId"
              element={<BrainChaseRoomInWithId />}
            />
            <Route
              path="/brainchase/:roomId/:hash/game"
              element={<BrainChaseGame />}
            />
            <Route path="/stats/:eventId/:roomId" element={<Statistics />} />
            <Route path="/archives/:eventId/:roomId" element={<Statistics />} />
            <Route
              path="/stats/:eventId/:roomId/:probId"
              element={<Statistics />}
            />
            <Route
              path="/archives/:eventId/:roomId/:probId"
              element={<Statistics />}
            />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/room" element={<AdminRoomIn />} />
            <Route path="/admin/set" element={<AdminSetEnter />} />
            <Route path="/admin/v2room" element={<AdminV2RoomIn />} />
            <Route path="/admin/v2room/:roomId" element={<V2RoomManager />} />
            <Route path="/admin/v2room/:roomId/screen" element={<V2Screen />} />
            <Route
              path="/admin/brainchase"
              element={<AdminBrainChaseRoomIn />}
            />
            <Route
              path="/admin/brainchase/:roomId"
              element={<AdminBrainChaseRoomManager />}
            />
            <Route
              path="/admin/brainchase/:roomId/screen"
              element={<AdminBrainChaseScreen />}
            />
            <Route
              path="/admin/sequence/:roomId/screen"
              element={<SequenceScreen />}
            />
            <Route path="/race/:eventId" element={<Setter />} />
            <Route path="/word/:roomId" element={<SiritoriRoomIn />} />
            <Route path="/word/:roomId/:hash/game" element={<SiritoriGame />} />
            <Route path="/duel/:roomId/:hash/renda" element={<Renda />} />
            <Route
              path="/nazo-cluster-quiz"
              element={<NazoClusterQuizGame />}
            />
            <Route
              path="/nazo-cluster-quiz/admin"
              element={<AdminNazoClusterQuizGame />}
            />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
