"use client";

import {
  DocumentData,
  QueryConstraint,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  onSnapshot,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import { db } from "..";

type useCollectionArg = {
  path?: string | null;
  constraints?: QueryConstraint[];
  checkEnable?: boolean;
};

const useCollection = <T>({ path, constraints = [] }: useCollectionArg) => {
  const [dataList, setDataList] = useState<T[]>([]);
  const [docList, setDocList] = useState<QueryDocumentSnapshot<DocumentData>[]>(
    []
  );

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!db) {
      return;
    }
    if (!path) {
      return;
    }

    const unsub = onSnapshot(
      query(collection(db, path), ...constraints),
      (querySnapshot) => {
        const doc = querySnapshot.docs;
        setDocList(doc);
        const dataList = doc.map(
          (d) =>
            ({
              ...d.data(),
              id: d.id,
            } as T)
        );
        setDataList(dataList);
        setLoading(false);
      }
    );
    return unsub;
  }, [db, constraints, path]);
  return { docList, loading, dataList };
};

const useCollectionOnce = <T>({
  path,
  constraints = [],
  checkEnable = true,
}: useCollectionArg): [T[] | undefined, boolean] => {
  const [dataList, setDataList] = useState<T[]>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!path) {
      return;
    }
    const fetchData = async () => {
      if (!db) {
        return;
      }
      if (!path) {
        return;
      }
      const colRef = query(collection(db, path), ...constraints);

      const colSnap = await getDocs(colRef);
      if (!colSnap.empty) {
        console.log(path, "found");
        const dataList = colSnap.docs.map((d) => d.data() as T);
        setDataList(dataList);
      }
      setLoading(false);
    };
    fetchData();
  }, [db, constraints, path]);

  return [dataList, loading];
};

export { useCollection, useCollectionOnce };
