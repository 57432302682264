import { TableRow, TableCell, Button, TextField } from "@mui/material";
import { FC, Key, useRef, useState } from "react";
import {
  deleteField,
  DocumentData,
  DocumentReference,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { BrainChaseUser } from "../../player/BrainChaseGame";
import { BrainChaseRoom, BrainChaseSet } from "../AdminBrainChaseRoomManager";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";

type AdminUserTableRowProps = {
  user: BrainChaseUser;
  roomSet: BrainChaseSet | null;
  room: BrainChaseRoom | null;
  userRef: DocumentReference<DocumentData, DocumentData>;
};

export const AdminUserTableRow: FC<AdminUserTableRowProps> = (props) => {
  const { user, userRef, roomSet, room } = props;

  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");
  const [inputName, setInputName] = useState("");
  const [editName, setEditName] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  const handleSave = () => {
    if (inputName !== user.name || inputName !== "") {
      setDoc(
        userRef,
        {
          displayName: inputName,
        },
        {
          merge: true,
        }
      );
    } else {
      setDoc(
        userRef,
        {
          displayName: deleteField(),
        },
        {
          merge: true,
        }
      );
    }
    setEditName(false);
  };

  return (
    <TableRow>
      <TableCell>
        {!editName ? (
          user.displayName ? (
            <>
              <span
                style={{
                  textDecoration: "line-through",
                }}
              >
                {user.name}
              </span>
              →{user.displayName}
            </>
          ) : (
            user.name
          )
        ) : (
          <TextField
            inputRef={ref}
            value={inputName}
            onChange={(e) => {
              setInputName(e.target.value);
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {!editName ? (
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            size={"small"}
            onClick={() => {
              setEditName(!editName);
              setInputName(user.name);
              setTimeout(() => {
                ref.current?.focus();
              }, 100);
            }}
          >
            名前変更
          </Button>
        ) : (
          <Button
            variant="outlined"
            size={"small"}
            startIcon={<SaveIcon />}
            onClick={handleSave}
          >
            保存
          </Button>
        )}
      </TableCell>
      <TableCell>
        {roomSet && (roomSet?.classes[parseInt(user.class ?? "0")].class ?? "")}
      </TableCell>
      <TableCell>{user.currentProblem}</TableCell>
      <TableCell>
        {roomSet &&
        user.problem?.[roomSet?.problems.length - 1]?.time &&
        (room?.startTime as Timestamp)
          ? (user.problem?.[roomSet?.problems.length - 1]?.time as Timestamp)
              .toDate()
              .getTime() /
              1000 -
            (room?.startTime as Timestamp)?.toDate().getTime() / 1000 -
            defaultOffsetTime
          : null}
      </TableCell>
      <TableCell>{user.rank}</TableCell>
    </TableRow>
  );
};
