import { Problem, RawProblem } from "../../../type";

export const convertRawProblemToProblem = (
  problem: RawProblem,
  probSetId: string
): Problem => {
  return {
    problemId: problem.problemId,
    inputType: problem.inputType,
    judgeTiming: problem.judgeTiming,
    answers: problem.answer.split(","),
    answerImgUrl:
      problem.answerImage === ""
        ? []
        : problem.answerImage.split(",").map((e) => probSetId + "/" + e),
    choices: problem.choices.split(","),
    timeLimit: problem.time === "なし" ? 6000 : parseFloat(problem.time),
    baseScore: problem.rankPoint.split(",").map((e: string) => parseInt(e)),
    beforeImgUrl:
      (problem.beforeImage ?? "") === ""
        ? []
        : problem.beforeImage.split(",").map((e) => probSetId + "/" + e),
    problemImageSwitchingTime:
      (problem.problemImageSwitchingTime ?? "") === ""
        ? []
        : problem.problemImageSwitchingTime
            .split(",")
            .map((e: string) => parseFloat(e)),
    imgUrl: problem.problemImage.split(",").map((e) => probSetId + "/" + e),
    scoreTimeCoefficient: parseFloat(
      problem.timeCoefficient === "なし" ? "0" : problem.timeCoefficient || "0"
    ),
    remainingSubmitCount:
      problem.answerCount === "無制限" ? 10000 : parseInt(problem.answerCount),
    pagePoint:
      (problem.pagePoint ?? "") === ""
        ? undefined
        : problem.pagePoint.split(",").map((e: string) => parseFloat(e)),
  };
};
