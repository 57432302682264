import { useEffect, useState } from "react";

import { listenAllUserHash } from "../../api/firestore/room/listenAllUserHash";

export const useAllUserHash = (roomId: string) => {
  const [allUserHash, setAllUserHash] = useState<any[]>([]);
  useEffect(() => {
    if (!roomId) {
      return;
    }
    return listenAllUserHash(roomId, setAllUserHash);
  }, [roomId]);
  return allUserHash;
};
