import { Card, CardContent, CardMedia } from "@mui/material";
import { FC } from "react";
import styled from "styled-components";

import { imageRoot } from "../../v2arena";

type WaitProblemProps = {
  remainingSubmitCount: number;
  probInd: number;
  imgUrls?: string[] | null;
  page: number;
};
export const WaitProblem: FC<WaitProblemProps> = ({
  remainingSubmitCount,
  probInd,
  imgUrls,
  page = 0,
}: WaitProblemProps) => {
  console.log(imgUrls, imgUrls?.[page]);
  return (
    <>
      <p>
        解答可能回数：
        {remainingSubmitCount >= 999 ? "∞" : remainingSubmitCount}
      </p>
      <Text>第{probInd + 1}問</Text>
      {imgUrls && imgUrls[page] && imgUrls[page] !== "" && (
        <Card sx={{ width: "92vw", height: "69vw" }}>
          <CardMedia>
            <Img src={imageRoot + imgUrls[page]} alt="Problem" />
          </CardMedia>
        </Card>
      )}
    </>
  );
};

const Text = styled.p`
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  padding: 1rem 0;
  user-select: none;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
