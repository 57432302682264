import { Button, Container, TextField, Typography } from "@mui/material";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { FC, useState } from "react";
import { hashString } from "react-hash-string";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { db } from "../../index";

const makeHash = (str: string) => {
  let ret = "";
  ret += String(Math.abs(hashString(str)));
  while (ret.length < 10) ret += "0";
  let EmanReyalp = str;
  EmanReyalp = EmanReyalp.split("").reverse().join("");
  ret += String(Math.abs(hashString(EmanReyalp)));
  while (ret.length < 20) ret += "0";
  return ret;
};

const RoomIn: FC = () => {
  const [playerName, setPlayerName] = useState<string>("");
  const [roomId, setRoomId] = useState<string>("myroom");
  const [modalMessage, setModalMessage] = useState<string>("");
  const navigate = useNavigate();

  const intoRoom = () => {
    if (playerName === "") return;
    const hash = makeHash(playerName);
    (async () => {
      const roomDocSnap = await getDoc(doc(db, "rooms", roomId));
      if (!roomDocSnap.exists()) {
        setModalMessage("存在しないルームIDです");
        return;
      }
      const hashDocSnap = await getDoc(doc(db, "rooms", roomId, "hash", hash));
      if (hashDocSnap.exists()) {
        if (hashDocSnap.data()?.name === playerName) {
          navigate("/room/" + roomId + "/" + hash + "/game");
        } else {
          setModalMessage("その名前ではルームに入れません");
          return;
        }
      }
      setDoc(doc(db, "rooms", roomId, "hash", hash), {
        name: playerName,
      });
      navigate("/room/" + roomId + "/" + hash + "/game");
    })();
  };

  let subtitle: HTMLHeadingElement | null;

  function afterOpenModal() {
    if (subtitle) subtitle.style.color = "#f00";
  }

  function closeModal() {
    setModalMessage("");
  }

  return (
    <Container
      sx={{
        margin: "auto",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mx: "auto",
          my: 1,
        }}
      >
        入室
      </Typography>
      <br />
      <TextField
        label="ルームID"
        type="text"
        value={roomId}
        onChange={(e: any) => {
          setRoomId(e.target.value);
        }}
        sx={{ mx: "auto", my: 1 }}
      />
      <br />
      <TextField
        label="名前"
        value={playerName}
        onKeyPress={(e: any) => {
          if (e.key == "Enter") intoRoom();
        }}
        onChange={(e: any) => {
          setPlayerName(e.target.value);
        }}
        sx={{ mx: "auto", my: 1 }}
      />
      <br />
      <Button
        onClick={intoRoom}
        variant={"contained"}
        size={"large"}
        sx={{ my: 1 }}
      >
        入室
      </Button>
      <Modal
        contentLabel="Example Modal"
        isOpen={modalMessage !== ""}
        style={customStyles}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{modalMessage}</h2>
        <button onClick={closeModal}>OK</button>
      </Modal>
    </Container>
  );
};

export default RoomIn;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    margin: "auto",
    transform: "translate(-50%, -50%)",
  },
};
