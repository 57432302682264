import Problem1 from "../../../static/png/Problems.001.png";
import Problem2 from "../../../static/png/Problems.002.png";
import Problem3 from "../../../static/png/Problems.003.png";
import Problem4 from "../../../static/png/Problems.004.png";
import Problem5 from "../../../static/png/Problems.005.png";
import Problem6 from "../../../static/png/Problems.006.png";
import Problem7 from "../../../static/png/Problems.007.png";
import Problem8 from "../../../static/png/Problems.008.png";
import Problem9 from "../../../static/png/Problems.009.png";
import Problem10 from "../../../static/png/Problems.010.png";
import Problem11 from "../../../static/png/Problems.011.png";
import Problem12 from "../../../static/png/Problems.012.png";
import Problem13 from "../../../static/png/Problems.013.png";
import Problem14 from "../../../static/png/Problems.014.png";
import Problem15 from "../../../static/png/Problems.015.png";
import Problem16 from "../../../static/png/Problems.016.png";
import Problem17 from "../../../static/png/Problems.017.png";
import Problem18 from "../../../static/png/Problems.018.png";
import Problem19 from "../../../static/png/Problems.019.png";
import Problem20 from "../../../static/png/Problems.020.png";
import { questions2nd_1, questions2nd_2 } from "./2nd";

export const questions1 = [
  {
    id: 1,
    image: Problem1,
    answers: ["ちーず", "チーズ", "cheese"],
    hint: "ハト→ハート, 地図→？？？",
  },
  {
    id: 2,
    image: Problem2,
    answers: ["しょうが", "生姜", "ショウガ"],
    hint: "右上のイラストは「ダイコン」です",
  },
  {
    id: 3,
    image: Problem3,
    answers: ["たまご", "卵", "玉子", "タマゴ"],
    hint: "左のイラストは「タコ」です",
  },
  {
    id: 4,
    image: Problem4,
    answers: ["すいか", "スイカ", "西瓜", "水瓜"],
    hint: "同じ数字には同じ文字が入ります。入れた後の文章から答えがわかります。",
  },
  {
    id: 5,
    image: Problem5,
    answers: ["さつまいも", "サツマイモ", "さつま芋", "薩摩芋", "サツマ芋"],
    hint: "「牛」「車」が「とう」「だい」になります。「本」「紙」も→？？？？？",
  },
  {
    id: 6,
    image: Problem6,
    answers: [
      "ONION",
      "onion",
      "おにおん",
      "オニオン",
      "🧅",
      "ｏｎｉｏｎ",
      "たまねぎ",
      "玉葱",
      "玉ねぎ",
    ],
    hint: "OFFが見えているということは今の状態は？",
  },
  {
    id: 7,
    image: Problem7,
    answers: ["ぱん", "パン"],
    hint: "イラストにその名前が1文字ずつ対応します。",
  },
  {
    id: 8,
    image: Problem8,
    answers: ["ぶろっこりー", "ブロッコリー"],
    hint: "右上のイラストは「ユニコーン」です。",
  },
  {
    id: 9,
    image: Problem9,
    answers: ["もやし", "モヤシ"],
    hint: "「あ」〜「そ」までのどれかが入ります。",
  },
  {
    id: 10,
    image: Problem10,
    answers: ["めろん", "メロン", "MELON", "melon", "🍈"],
    hint: "2種類の矢印があり、本数が違うようですね。",
  },
];

const questions2 = [
  {
    id: 1,
    image: Problem11,
    answers: ["ぶた", "ブタ", "豚"],
    hint: "ハートがバードになっていますね。答えは２通りありますが、食べ物の名前になります。",
  },
  {
    id: 2,
    image: Problem12,
    answers: ["きゅうり", "胡瓜", "キュウリ"],
    hint: "どうやら大きさを測ることに意味があるようです。答えは野菜の名前になります。",
  },
  {
    id: 3,
    image: Problem13,
    answers: ["きのこ", "キノコ"],
    hint: "左のイラストは「つちのこ」です。",
  },
  {
    id: 4,
    image: Problem14,
    answers: ["たこ", "たこ"],
    hint: "同じ数字には同じ文字が入ります。",
  },
  {
    id: 5,
    image: Problem15,
    answers: ["はむ", "ハム"],
    hint: "車のイラストは先ほどの問題から反転しているようです。向きに意味があるようですね。下のイラストは「ハト」「オウム」です。",
  },
  {
    id: 6,
    image: Problem16,
    answers: ["人参", "にんじん", "ニンジン"],

    hint: "入が裏返っていますね",
  },
  {
    id: 7,
    image: Problem17,
    answers: ["さんどいっち", "サンドイッチ", "🥪", "sandwich"],
    hint: "それぞれの問題を解いて答えを埋めましょう。答えは6文字",
  },
  {
    id: 8,
    image: Problem18,
    answers: ["かれい", "カレイ", "鰈"],
    hint: "カが5個、カが2個、カが？、答えは「魚」です。",
  },
  {
    id: 9,
    image: Problem19,
    answers: ["たかな", "高菜", "タカナ"],
    hint: "「カタカナ」が入ります",
  },
  {
    id: 10,
    image: Problem20,
    answers: ["れもん", "レモン", "LEMON", "lemon"],
    hint: "「MELON」が答えの問題が切り貼りされていますね。",
  },
];

export const events: {
  [key: string]: {
    title: string;
    descriptions?: JSX.Element[];
    questions: {
      id: number;
      image: string;
      hint: string;
      answers: string[];
    }[];
  };
} = {
  riddler230425a: {
    title: "読者対決！速解きタイムアタック",
    descriptions: [
      <p key={0}>ナゾトレ誌面にある10問の速解き</p>,
      <p key={1}>答えはすべて食べ物の名前になります。</p>,
      <p key={2}>30秒経過でヒントが公開されます。</p>,
    ],
    questions: questions1,
  },
  riddler230425b: {
    title: "速解きタイムアタック改造版",
    descriptions: [
      <p key={0}>速解きタイムアタックを一隅が改造した10問の速解き</p>,
      <p key={1}>答えはすべて食べ物の名前になります。</p>,
      <p key={2}>30秒経過でヒントが公開されます。</p>,
    ],
    questions: questions2,
  },
  riddler230829a: {
    title: "読者対決！速解きタイムアタック2",
    descriptions: [
      <p key={0}>ナゾトレ誌面にある10問の速解き</p>,
      <p key={1}>答えはすべて生き物の名前になります。</p>,
      <p key={2}>30秒経過でヒントが公開されます。</p>,
    ],
    questions: questions2nd_1,
  },
  riddler230829b: {
    title: "速解きタイムアタック2改造版",
    descriptions: [
      <p key={0}>速解きタイムアタックを一隅が改造した10問の速解き</p>,
      <p key={1}>答えはすべて生き物の名前になります。</p>,
      <p key={2}>30秒経過でヒントが公開されます。</p>,
    ],
    questions: questions2nd_2,
  },
};
