import { useCallback, useEffect, useState } from "react";

const ProgressBar = ({ value, max }: { value: number; max: number }) => {
  // プログレスバーの長さをパーセントで計算
  const width = (value / max) * 100;

  return (
    <div
      className="progress-bar-container"
      style={{
        height: "20px",
        width: "100%",
        backgroundColor: "#e0e0de",
        borderRadius: "50px",
        padding: "3px",
      }}
    >
      <div
        className="progress-bar"
        style={{
          height: "100%",
          width: `${width}%`,
          backgroundColor: "#76D275",
          borderRadius: "inherit",
          transition: "width .2s ease-in",
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
export const Renda = () => {
  const [power, setPower] = useState(0);
  const [clickList, setClickList] = useState<number[]>([]);
  const [ms, setMs] = useState<number>(0);

  const onClickButton = useCallback(() => {
    setClickList([...clickList, Date.now()]);
  }, [clickList]);

  useEffect(() => {
    const now = Date.now();
    const clickTime = clickList.map((click) => now - click);
    const power = clickTime.reduce((a, b) => a + Math.pow(0.5, b / 1000), 0);
    setPower(power);
  }, [clickList, ms]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMs((ms) => ms + 1);
    }, 100);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <h1>Renda</h1>
      <ProgressBar value={power} max={10} />
      <p>Power: {power.toFixed(2)}</p>
      <input type="button" value="連打" onClick={onClickButton} />
    </div>
  );
};
