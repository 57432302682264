import { Box, Button, Stack, Typography } from "@mui/material";
import { FieldValue, serverTimestamp, Timestamp } from "firebase/firestore";
import { createRef, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useDoc } from "../../../hooks/useDoc";
import { getServerTime } from "../../../lib/getServerTime";
import {
  BrainChaseRoom,
  BrainChaseSet,
} from "../admin/AdminBrainChaseRoomManager";
import background from "../image/red_black_bg.png";

export type BrainChaseUser = {
  class: string;
  name: string;
  displayName?: string;
  problem: {
    [key: string]: {
      time: Timestamp | FieldValue;
    };
  };
  currentProblem: number;
  rank?: number;
  lastAnswer?: string;
  lastAnswerAt?: Timestamp | FieldValue;
};

export type UserData = {
  class: string;
  name: string;
};

export const BrainChaseGame: React.FC = () => {
  const { roomId, hash } = useParams<{ roomId: string; hash: string }>();
  const [room] = useDoc<BrainChaseRoom>({
    path: "brainchase/" + roomId,
  });

  const [roomSet] = useDoc<BrainChaseSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const [userData, setUserData] = useDoc<BrainChaseUser>({
    path: "brainchase/" + roomId + "/user/" + hash,
  });

  const [answerMessage, setAnswerMessage] = useState<string>("");

  const currentProblem = userData?.currentProblem ?? 0;
  const playerName = userData?.name ?? "";

  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = createRef<HTMLInputElement>();
  const [isComposition, setIsComposition] = useState(false);

  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");
  const offsetTime = parseInt(
    roomSet?.classes[parseInt(userData?.class ?? "0")].time ?? "0"
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value === " ") {
      setInputValue("");
    } else {
      setInputValue(value);
    }
  };

  const [serverTime, setServerTime] = useState<any>();
  const [serverTimeDiff, setServerTimeDiff] = useState<number>(0);
  const start = room?.mode === "playing";
  const clearTime = useMemo(() => {
    return userData && room && userData.problem?.[currentProblem - 1]
      ? ((userData.problem[currentProblem - 1]?.time as Timestamp)
          ?.toDate()
          .getTime() - (room.startTime as Timestamp)?.toDate().getTime() ?? 0) /
          1000 -
          defaultOffsetTime
      : 0;
  }, [userData, room, currentProblem]);

  useEffect(() => {
    if (!start) {
      return;
    }
    if (!playerName) {
      return;
    }
    getServerTime(playerName !== "" ? playerName ?? "player" : "player").then(
      (serverTime: Date) => {
        setServerTimeDiff(serverTime.getTime() - new Date().getTime());
      }
    );

    const interval = setInterval(() => {
      setServerTime(new Date(new Date().getTime() + (serverTimeDiff ?? 0)));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [start, playerName]);

  useEffect(() => {
    if (answerMessage !== "") {
      setTimeout(() => {
        setAnswerMessage("");
      }, 2000);
    }
  }, [answerMessage]);

  const submit = () => {
    if (inputValue === "") return;
    if (!userData) {
      return;
    }
    if (
      !roomSet?.problems[currentProblem].answer.split(",").includes(inputValue)
    ) {
      setAnswerMessage("不正解です！");
      setInputValue("");
      setUserData({
        ...userData,
        lastAnswer: inputValue,
        lastAnswerAt: serverTimestamp(),
      });
      return;
    }
    setAnswerMessage("正解です！");
    setInputValue("");
    setUserData({
      ...userData,
      class: userData?.class ?? "0",
      problem: {
        ...userData?.problem,
        [currentProblem.toString()]: {
          time: serverTimestamp(),
        },
      },
      currentProblem: currentProblem + 1,
      lastAnswer: inputValue,
      lastAnswerAt: serverTimestamp(),
    });
    inputRef.current?.focus();
  };

  if (!roomSet || !room) {
    return <></>;
  }

  const countDown =
    offsetTime +
    defaultOffsetTime -
    (serverTime?.getTime() -
      (room.startTime as Timestamp)?.toDate()?.getTime()) /
      1000;
  const maxProblem = roomSet?.problems.length ?? 15;

  return (
    <Bg>
      {(room.mode === "waiting" ||
        (room.mode === "playing" && countDown > 0)) && (
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontFamily: '"Bebas Neue", sans-serif',
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "20vw",
              background: "linear-gradient(#fff,#ccc)",
              backgroundClip: "text",
              textFillColor: "transparent",
              mb: 8,
            }}
          >
            BRAIN CHASE
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mt: 2,
              fontWeight: "bold",
            }}
          >
            待機中
          </Typography>
          <Box sx={{ my: 2 }} />
          <Typography
            variant="h3"
            sx={{
              mt: 2,
              fontWeight: "bold",
            }}
          >
            あなたのクラス
          </Typography>
          {roomSet && (
            <Typography variant="h3" sx={{ mt: 2 }}>
              {roomSet?.classes[parseInt(userData?.class ?? "0")].class ?? ""}
            </Typography>
          )}
          <Box sx={{ my: 2 }} />
          {room.mode === "waiting" && (
            <>
              <Typography
                variant="h3"
                sx={{
                  mt: 2,
                  fontWeight: "bold",
                }}
              >
                スタートをお待ちください
              </Typography>
            </>
          )}
          {room.mode === "playing" && countDown > 0 && (
            <>
              <Typography
                variant="h3"
                sx={{
                  mt: 2,
                  fontWeight: "bold",
                }}
              >
                スタートまで
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  mt: 2,
                  fontSize: "6rem",
                }}
              >
                {Math.floor(Math.ceil(countDown) / 60)}:
                {("" + (Math.ceil(countDown) % 60)).padStart(2, "0")}
              </Typography>
            </>
          )}
        </Stack>
      )}
      {room.mode === "playing" &&
        countDown <= 0 &&
        currentProblem < maxProblem && (
          <>
            <Stack
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                width: "100vw",
              }}
            >
              <Box sx={{ height: 20 }} />
              <div
                className="question"
                style={{
                  width: "100%",
                  border: "none",
                  maxWidth: "100%",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                <img
                  src={roomSet?.problems[currentProblem].image ?? ""}
                  alt="問題画像"
                  style={{
                    margin: 0,
                    opacity: 0.9,
                    border: "2vw solid #d41b05",
                  }}
                />
              </div>
              <Box sx={{ height: 5 }} />
              <Typography
                variant="h5"
                sx={{
                  mt: 2,
                }}
              >
                {answerMessage}
              </Typography>
              <Box sx={{ height: 10 }} />
              <input
                type="text"
                value={inputValue}
                ref={inputRef}
                onChange={handleInputChange}
                className="answer"
                onCompositionStart={() => setIsComposition(true)}
                onCompositionEnd={() => setIsComposition(false)}
                onKeyDown={(e) => {
                  if (isComposition) return;
                  if (e.key === "Enter") {
                    submit();
                  }
                }}
                autoFocus
              />
              <Box sx={{ height: 10 }} />
              <Button
                sx={{
                  my: 10,
                }}
                size="large"
                onClick={submit}
                variant={"contained"}
              >
                送信
              </Button>
            </Stack>
          </>
        )}
      {(room.mode === "end" ||
        (room.mode === "playing" && currentProblem === maxProblem)) && (
        <>
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <Typography variant="h1" sx={{ mt: 2 }}>
              Result
            </Typography>
            <Typography variant="h3" sx={{ mt: 2 }}>
              名前:{" "}
              {userData?.displayName ? (
                <>
                  <span
                    style={{
                      textDecoration: "line-through",
                    }}
                  >
                    {userData?.name}
                  </span>
                  → {userData?.displayName}
                </>
              ) : (
                userData?.name
              )}
            </Typography>
            <Typography variant="h3" sx={{ mt: 2 }}>
              クラス:{" "}
              {roomSet?.classes[parseInt(userData?.class ?? "0")].class ?? ""}
            </Typography>
            <Typography variant="h4" sx={{ mt: 2 }}>
              (+{offsetTime}秒)
            </Typography>
            <Box />
            <Typography variant="h3" sx={{ mt: 2 }}>
              成績:{" "}
              {currentProblem === maxProblem
                ? `${(clearTime / 60) | 0}分${(
                    "" +
                    (Math.floor(clearTime) % 60)
                  ).padStart(2, "0")}秒${Math.floor(clearTime * 10) % 10}
                でクリア！`
                : `Q${currentProblem + 1}で終了`}
            </Typography>
            {userData?.rank && (
              <Typography variant="h3" sx={{ mt: 2 }}>
                順位: {userData.rank}
              </Typography>
            )}

            <Button
              onClick={() => {
                const url = `https://twitter.com/intent/tweet?text=${roomSet.config.tweetText.replace(
                  "@rank@",
                  "" + (userData?.rank ?? "0")
                )}`;
                window.open(url, "_blank");
              }}
              variant={"contained"}
              size={"large"}
              sx={{ mx: "auto", my: 5 }}
            >
              Twitterでシェア
            </Button>
          </Stack>
        </>
      )}
    </Bg>
  );
};

const Bg = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-position: bottom;
  font-family: "Noto Sans JP", sans-serif;
`;
