import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import React, { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { db } from "../../../index";

let unsub = () => {
  return;
};

type RankingData = {
  rank: number;
  name: string;
  score: string;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "2vw",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type ResultProps = {
  roomId: string;
};
export const ScreenResultRanking: React.FC<ResultProps> = (props) => {
  const [playerName, setPlayerName] = React.useState<string>("");
  const [data, setData] = React.useState<any[]>([]);
  const [inf, setInf] = React.useState<number>(3000000100);
  const [lastUpdate, setLastUpdate] = React.useState<any>();
  const location = useLocation();
  const { roomId } = props;

  console.log("**Ranking**");
  const setRanking = useCallback(
    (roomId: string, querySnapshot: any) => {
      (async () => {
        console.log("setRanking");
        let probs: string[] = [];
        querySnapshot.forEach((doc: any) => {
          probs = [...probs, doc.id];
        });
        let tmpData: RankingData[] = [];
        let rawData: any[] = [];
        for (let i = 0; i < probs.length; i++) {
          const scoreDoc = await getDoc(
            doc(db, "v2rooms", roomId, "scores", probs[i])
          );
          rawData = [...rawData, [inf - scoreDoc.data()?.totalScore, probs[i]]];
        }
        rawData.sort();
        for (let i = 0; i < probs.length; i++) {
          let rank = 0;
          for (let j = 0; j < i; j++) {
            if (rawData[i][0] == rawData[j][0]) break;
            rank++;
          }
          const data = {
            rank: rank + 1,
            name: rawData[i][1],
            score: String((inf - rawData[i][0]).toFixed(2)),
          };
          tmpData = [...tmpData, data];
        }
        console.log("data", tmpData);
        setData(tmpData);
      })();
    },
    [setData]
  );

  useEffect(() => {
    (async () => {
      if (roomId === "") {
        return;
      }
      const querySnapshot = await getDocs(
        collection(db, "v2rooms", roomId, "scores")
      );
      const roomDoc = await getDoc(doc(db, "v2rooms", roomId));
      setLastUpdate(roomDoc.data()?.lastUpdate);
      setRanking(roomId, querySnapshot);
    })();
  }, [roomId, setRanking]);

  useEffect(() => {
    console.log("effect", roomId);
    if (roomId === "") {
      return;
    }
    unsub();
    unsub = onSnapshot(
      doc(db, "v2rooms", roomId),
      { includeMetadataChanges: true },
      async (roomDoc) => {
        if (Number(lastUpdate) == Number(roomDoc.data()?.lastUpdate)) return;
        const querySnapshot = await getDocs(
          collection(db, "v2rooms", roomId, "scores")
        );
        setRanking(roomId, querySnapshot);
        console.log(roomDoc.data()?.lastUpdate);
        console.log(lastUpdate);
        setLastUpdate(roomDoc.data()?.lastUpdate);
        return;
      }
    );
  }, [roomId, lastUpdate]);

  const listData = useMemo(
    () =>
      data.map((data) => (
        <StyledTableRow key={data.rank}>
          <StyledTableCell>{data.rank}位 </StyledTableCell>
          <StyledTableCell align="right">{data.name}</StyledTableCell>
          <StyledTableCell align="right">{data.score}pt</StyledTableCell>
        </StyledTableRow>
      )),
    [data]
  );
  console.log("ranking", listData);

  return (
    <>
      <Typography
        variant="h3"
        sx={{ mt: 2, mx: "auto", textAlign: "center", fontSize: 18, my: 2 }}
      >
        ランキング
      </Typography>
      <Table
        sx={{
          minWidth: 360,
          width: "60vw",
          maxWidth: "90vw",
          mx: "auto",
          fontSize: "1vw",
        }}
        aria-label="customized table"
      >
        <TableBody>{listData}</TableBody>
      </Table>
    </>
  );
};
