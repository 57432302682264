import React from "react";
import { Link } from "react-router-dom";

const Login: React.FC = (props) => {
  return (
    <>
      <p>LOCKボタン</p>
      <br />
      <Link to="/room">参加者として開始</Link>
      <br />
      <Link to="/admin">出題者として開始</Link>
    </>
  );
};

export default Login;
