import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { collection, onSnapshot } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";

import { db } from "../../../index";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "1.5vw",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type ResultProps = {
  roomId: string;
  probId: string;
  displaySubmission?: boolean;
};
export const SubmissionResult: React.FC<ResultProps> = (props) => {
  const { roomId, probId, displaySubmission } = props;
  const [submitDocs, setSubmitDocs] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    if (roomId === "") {
      return;
    }
    if (probId === "") {
      return;
    }
    const unsub = onSnapshot(
      collection(db, "v2rooms", roomId, "submits", probId, "users"),
      { includeMetadataChanges: true },
      async (querySnapshot) => {
        querySnapshot.forEach((submitDoc) => {
          setSubmitDocs((prev) => {
            const ret = { ...prev };
            console.log(prev, submitDoc, submitDoc.id, submitDoc.data());
            ret[submitDoc.id] = submitDoc.data();
            return ret;
          });
        });
        return;
      }
    );
    return unsub;
  }, [roomId, probId]);

  const listData = useMemo(() => {
    console.log(submitDocs);
    if (submitDocs === undefined) {
      return [];
    }
    const data = Object.entries(submitDocs)
      .filter((e) => e[1].correct)
      .sort((a, b) => {
        return a[1].submitTime - b[1].submitTime;
      })
      .reverse();
    return data.map((e, i) => {
      return (
        <StyledTableRow key={"table-" + (i + 1)}>
          <StyledTableCell>{i + 1}位 </StyledTableCell>
          <StyledTableCell align="right">{e[0]}</StyledTableCell>
          {displaySubmission ? (
            <StyledTableCell align="right">{e[1].answer}s</StyledTableCell>
          ) : (
            <StyledTableCell align="right">
              {(e[1].submitTime / 1000).toFixed(2)}s
            </StyledTableCell>
          )}
        </StyledTableRow>
      );
    });
  }, [submitDocs]);

  return (
    <>
      <Typography
        variant="h3"
        sx={{ mt: 2, mx: "auto", textAlign: "center", fontSize: 18, my: 2 }}
      >
        提出ランキング
      </Typography>
      <Table
        sx={{
          width: "30vw",
          mx: "auto",
          fontSize: "0.7vw",
        }}
        aria-label="customized table"
      >
        <TableBody>{listData}</TableBody>
      </Table>
    </>
  );
};
