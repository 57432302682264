import {
  Box,
  Card,
  CardMedia,
  LinearProgress,
  Typography,
} from "@mui/material";
import { FC } from "react";
import styled from "styled-components";

import { imageRoot } from "../../v2arena";

type DisplayProblemProps = {
  immediateCheck?: boolean;
  isCorrect: boolean;
  remainingTime: number;
  timeLimit: number;
  nowPoint: number;
  imgUrls?: string[] | null;
  page: number;
  closeImage: boolean;
};

export const DisplayProblem: FC<DisplayProblemProps> = (props) => {
  const {
    immediateCheck,
    isCorrect,
    remainingTime,
    timeLimit,
    nowPoint,
    imgUrls,
    page = 0,
    closeImage = false,
  } = props;
  const str = immediateCheck ? (isCorrect ? "正解" : "未正解") : "?";
  const progress = (remainingTime / timeLimit) * 100;
  return (
    <ProbWrapper>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body1" color="text.primary">{`${(
            "0" + Math.floor(remainingTime / 60)
          ).slice(-2)}:${("0" + Math.floor(remainingTime % 60)).slice(
            -2
          )}`}</Typography>
        </Box>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </Box>
      <p>{nowPoint.toFixed(0)} point</p>
      <br />
      {imgUrls &&
        imgUrls[page] &&
        imgUrls[page] !== "" &&
        remainingTime > 0 &&
        !closeImage && (
          <Card sx={{ width: "92vw", height: "69vw" }}>
            <CardMedia>
              <ProblemImg src={imageRoot + imgUrls[page]} alt="Problem" />
            </CardMedia>
          </Card>
        )}
      {remainingTime <= 0 && (
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          制限時間が終了しました
        </Typography>
      )}

      <br />
    </ProbWrapper>
  );
};

const ProbWrapper = styled.div`
  user-select: none;
`;

const ProblemImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
