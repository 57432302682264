import { Card, CardMedia, Typography } from "@mui/material";
import { FC } from "react";

import { imageRoot } from "../../v2arena/v2arena";
import styled from "styled-components";

type WaitProblemProps = {
  probInd: number;
  imgUrls?: string[] | null;
  page: number;
};
export const WaitProblem: FC<WaitProblemProps> = ({
  probInd,
  imgUrls,
  page = 0,
}) => {
  return (
    <>
      <Typography variant="h3" sx={{ mt: 2 }}>
        第{probInd + 1}問
      </Typography>
      {imgUrls && imgUrls[page] && imgUrls[page] !== "" && (
        <Card sx={{ width: "92vw", height: "69vw" }}>
          <CardMedia>
            <Img src={imageRoot + imgUrls[page]} alt="Problem" />
          </CardMedia>
        </Card>
      )}
    </>
  );
};

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
