import {
  Box,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Timestamp } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useCollection } from "../../../hooks/useCollection";
import { useDoc } from "../../../hooks/useDoc";
import { getServerTime } from "../../../lib/getServerTime";
import { Bg } from "../common/Bg";
import { ScreenTimer } from "../component/ScreenTimer";
import { BrainChaseUser } from "../player/BrainChaseGame";
import { BrainChaseRoom, BrainChaseSet } from "./AdminBrainChaseRoomManager";

type RankUser = {
  name: string;
  currentProblem: number;
  clearTime: number | null;
  lastTime: number;
};

export const AdminBrainChaseScreen: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const { docList: users } = useCollection<BrainChaseUser>({
    path: roomId ? "brainchase/" + roomId + "/user" : null,
  });
  const [room] = useDoc<BrainChaseRoom>({
    path: roomId ? "brainchase/" + roomId : null,
  });
  const [roomSet] = useDoc<BrainChaseSet>({
    path: room ? "sets/" + room.setId : null,
  });

  const [userRanks, setUserRanks] = useState<RankUser[]>([]);

  const defaultOffsetTime = parseInt(roomSet?.config?.countDown ?? "10");

  useEffect(() => {
    if (!users) {
      return;
    }
    if (users.length === 0) {
      return;
    }

    const ranks = users
      .filter(
        (doc) => doc.data().currentProblem && doc.data().currentProblem > 0
      )
      .map((doc) => {
        const r = doc.data();
        return {
          name: r.displayName ?? r.name,
          currentProblem: r.currentProblem ?? 0,
          clearTime: roomSet
            ? r.problem[roomSet?.problems.length - 1]?.time.toDate().getTime() /
                1000 -
                (room?.startTime as Timestamp)?.toDate().getTime() / 1000 -
                defaultOffsetTime ?? null
            : null,
          lastTime:
            r.currentProblem > 0
              ? r.problem[r.currentProblem - 1]?.time.toDate().getTime() /
                  1000 -
                (room?.startTime as Timestamp)?.toDate().getTime() / 1000
              : 0,
        } as RankUser;
      })
      .sort((a, b) => {
        if (a.clearTime && b.clearTime) {
          return a.clearTime - b.clearTime;
        }
        if (a.currentProblem === b.currentProblem) {
          return a.lastTime - b.lastTime;
        }
        return b.currentProblem - a.currentProblem;
      });

    if (ranks.length > 0) {
      setUserRanks(ranks);
    }
  }, [users]);

  const stats = useMemo(() => {
    const stats: { [key: string]: number } = {};
    users.forEach((doc) => {
      const r = doc.data();
      if (r.currentProblem in stats) {
        stats[r.currentProblem ?? 0]++;
      } else {
        stats[r.currentProblem ?? 0] = 1;
      }
    });
    return stats;
  }, [users]);

  const [serverTime, setServerTime] = useState<Date | null>(null);
  const [serverTimeDiff, setServerTimeDiff] = useState<number>(0);
  const start = room?.mode === "playing";
  useEffect(() => {
    if (!start) {
      return;
    }
    getServerTime("screen").then((serverTime: Date) => {
      setServerTimeDiff(serverTime.getTime() - new Date().getTime());
    });

    const interval = setInterval(() => {
      setServerTime(new Date(new Date().getTime() + (serverTimeDiff ?? 0)));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [start]);

  return (
    <Bg>
      <Grid
        container
        sx={{
          my: 4,
          height: "100vh",
          userSelect: "none",
        }}
      >
        <Grid item xs={8}>
          <Stack>
            {userRanks.map((r, i) => (
              <Card
                key={i}
                sx={{
                  my: 1,
                  py: 1,
                  mx: 4,
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    width: "100%",
                    px: 2,
                    fontSize: "1.5rem",
                  }}
                >
                  <Grid item xs={1}>
                    <Typography
                      sx={{
                        fontSize: "4rem",
                        fontWeight: "bold",
                      }}
                      variant="h1"
                    >
                      {i + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      sx={{
                        fontSize: "4rem",
                        fontWeight: "bold",
                      }}
                      variant="h1"
                    >
                      {r.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        fontSize: "4rem",
                        fontWeight: "bold",
                      }}
                      variant="h1"
                    >
                      {r.clearTime
                        ? `${Math.floor(r.clearTime / 60)}:${(
                            "" +
                            (Math.floor(r.clearTime) % 60)
                          ).padStart(2, "0")}:${
                            "" + (Math.floor(r.clearTime * 10) % 10)
                          }`
                        : "Q." + (r.currentProblem + 1)}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <ScreenTimer
              room={room ?? null}
              roomSet={roomSet ?? null}
              serverTime={serverTime ?? null}
            />
            <Box>
              <Table
                sx={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                }}
              >
                <TableHead>
                  <TableCell>
                    <Typography
                      sx={{
                        fontSize: "3rem",
                        fontWeight: "bold",
                      }}
                      variant="h1"
                    >
                      Q
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        fontSize: "3rem",
                        fontWeight: "bold",
                      }}
                      variant="h1"
                    >
                      人数
                    </Typography>
                  </TableCell>
                </TableHead>
                <TableBody>
                  {Object.entries(stats)
                    .sort((a, b) => -(parseInt(a[0]) - parseInt(b[0])))
                    .map(([q, n], i) => (
                      <TableRow key={i}>
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: "3rem",
                              fontWeight: "bold",
                            }}
                            variant="h1"
                          >
                            {q === "15" ? "FINISH" : `Q${parseInt(q) + 1}`}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              fontSize: "3rem",
                              fontWeight: "bold",
                            }}
                            variant="h1"
                          >
                            {n}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Bg>
  );
};
