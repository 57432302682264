import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Button, Container, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Admin: React.FC = () => {
  const navigate = useNavigate();

  const manageRooms = () => {
    navigate(`/admin/v2room`);
  };
  const manageSet = () => {
    navigate("/admin/set");
  };

  return (
    <Container>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Typography key="3" color="text.primary">
          Admin
        </Typography>
      </Breadcrumbs>
      <Typography variant="h2" component="h1" gutterBottom>
        管理者メニュー
      </Typography>
      <Button onClick={manageSet} variant="contained" sx={{ mx: 2 }}>
        セット管理
      </Button>
      <Button onClick={manageRooms} variant="contained" sx={{ mx: 2 }}>
        v2ルーム管理
      </Button>
      <Button
        onClick={() => {
          navigate("/admin/brainchase");
        }}
        variant="contained"
        sx={{ mx: 2 }}
      >
        BrainChase管理
      </Button>
      <Button
        onClick={() => {
          navigate("/admin/sequence");
        }}
        variant="contained"
        sx={{ mx: 2 }}
      >
        KACHIDOKI管理
      </Button>
    </Container>
  );
};

export default Admin;
