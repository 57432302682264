import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import { FC } from "react";

import { SubmissionResult } from "../../admin/component/SubmissionResult";
import { imageRoot } from "../v2arena";

type DisplayProblemProps = {
  remainingTime: number;
  timeLimit: number;
  roomId: string;
  probId: string;
  imgUrls?: string[] | null;
  page?: number;
  closeImage: boolean;
};

export const ScreenDisplayProblem: FC<DisplayProblemProps> = (props) => {
  const {
    remainingTime,
    timeLimit,
    imgUrls,
    page = 0,
    roomId,
    probId,
    closeImage = false,
  } = props;
  const progress = (remainingTime / timeLimit) * 100;

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ minWidth: 150 }}>
              <Typography variant="h1" color="text.primary">{`${(
                "0" + Math.floor(remainingTime / 60)
              ).slice(-2)}:${("0" + Math.floor(remainingTime % 60)).slice(
                -2
              )}`}</Typography>
            </Box>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress
                variant="determinate"
                value={progress}
                sx={{ height: 20, my: 1 }}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <SubmissionResult roomId={roomId} probId={probId} />
        </Grid>

        {imgUrls && imgUrls[page] && imgUrls[page] !== "" && !closeImage && (
          <Grid item xs={8}>
            <img
              src={imageRoot + imgUrls[page]}
              alt="problem"
              style={{
                objectFit: "contain",
                width: "60vw",
                height: "70vh",
                margin: "auto",
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
