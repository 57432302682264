import styled from "@emotion/styled";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";

const timeLimit = 30;
export const SequenceScreen: FC = () => {
  const [page, setPage] = useState(0);

  const [titleImgUrl, setTitleImgUrl] = useState<string>("");
  const [imgUrls, setImgUrls] = useState<string[]>([]);
  const [start, setStart] = useState<boolean>(false);
  const [startTime, setStartTime] = useState<Date>(new Date());
  const [now, setNow] = useState<Date>(new Date());
  const remainingTime =
    timeLimit - (now.getTime() - startTime.getTime()) / 1000;
  const progress = (remainingTime / timeLimit) * 100;

  if (remainingTime < 0) {
    setStartTime(new Date());
    setPage((prev) => prev + 1);
  }
  if (page > imgUrls.length + Math.ceil(imgUrls.length / 4)) {
    setStart(false);
    setPage(0);
  }

  useEffect(() => {
    if (!start) {
      return;
    }
    const timer = window.setInterval(() => {
      if (start) {
        setNow(new Date());
      }
    }, 100);
    return () => {
      window.clearInterval(timer);
    };
  }, [start]);

  const [textField, setTextField] = useState<string>("");

  if (imgUrls.length === 0) {
    return (
      <Stack>
        <TextField
          multiline
          value={textField}
          rows={20}
          onChange={(e) => {
            setTextField(e.target.value);
          }}
        ></TextField>
        <Button
          variant="contained"
          onClick={() => {
            const urls = textField.split("\n").filter((e) => e !== "");
            const [title, ...rest] = urls;
            setTitleImgUrl(title);
            setImgUrls(rest);
          }}
        >
          反映
        </Button>
      </Stack>
    );
  }

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ minWidth: 150 }}>
          <Typography variant="h1" color="text.primary">{`${(
            "0" + Math.floor(remainingTime / 60)
          ).slice(-2)}:${("0" + Math.ceil(remainingTime % 60)).slice(
            -2
          )}`}</Typography>
        </Box>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={{ height: 20, my: 1 }}
          />
        </Box>
      </Box>

      <Grid
        container
        sx={{
          mx: "auto",
          width: "90%",
        }}
      >
        {!start ? (
          <Grid xs={12}>
            {titleImgUrl && (
              <Card
                sx={{ width: "100%", margin: "auto" }}
                onClick={() => {
                  setStartTime(new Date());
                  setNow(new Date());
                  setPage(0);
                  setStart(true);
                }}
              >
                <CardMedia>
                  <Img src={titleImgUrl} alt="Problem" />
                </CardMedia>
              </Card>
            )}
          </Grid>
        ) : page < imgUrls.length ? (
          <Grid xs={12}>
            {imgUrls && imgUrls[page] && imgUrls[page] !== "" && (
              <Card sx={{ width: "100%", margin: "auto" }}>
                <CardMedia>
                  <Img src={imgUrls[page]} alt="Problem" />
                </CardMedia>
              </Card>
            )}
          </Grid>
        ) : page < imgUrls.length + Math.ceil(imgUrls.length / 4) ? (
          [...new Array(4)]
            .map((e, i) => i)
            .map((e) => (
              <Grid xs={6} key={e}>
                {!start ? (
                  <Card
                    sx={{ width: "100%", margin: "auto" }}
                    onClick={() => {
                      if (!start) {
                        setStartTime(new Date());
                        setNow(new Date());
                        setStart(true);
                      }
                    }}
                  >
                    <CardMedia>
                      <Img
                        src={imgUrls[Math.floor(page - imgUrls.length) * 4 + e]}
                        alt="Problem"
                      />
                    </CardMedia>
                  </Card>
                ) : (
                  imgUrls &&
                  imgUrls[Math.floor((page - imgUrls.length) / 4) + e] &&
                  imgUrls[Math.floor((page - imgUrls.length) / 4) + e] !==
                    "" && (
                    <Card
                      sx={{ width: "100%", margin: "auto" }}
                      onClick={() => {
                        if (!start) {
                          setStartTime(new Date());
                          setNow(new Date());
                          setStart(true);
                        }
                      }}
                    >
                      <CardMedia>
                        <Img
                          src={
                            imgUrls[Math.floor(page - imgUrls.length) * 4 + e]
                          }
                          alt="Problem"
                        />
                      </CardMedia>
                    </Card>
                  )
                )}
              </Grid>
            ))
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
