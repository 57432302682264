import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  Breadcrumbs,
  Button,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AdminSetEdit } from "./AdminSetEdit";

export const AdminSetEnter: FC = (props) => {
  const [upperSetId, setUpperSetId] = useState<string>("");
  const [isSetEditStart, setIsSetEditStart] = useState<boolean>(false);
  const navigate = useNavigate();

  const setEditStart = () => {
    setIsSetEditStart(true);
  };

  if (isSetEditStart) {
    return <AdminSetEdit setIsWorking={setIsSetEditStart} setId={upperSetId} />;
  } else {
    return (
      <Container>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          <Link
            underline="hover"
            key="2"
            color="inherit"
            onClick={() => {
              navigate("/admin");
            }}
          >
            Admin
          </Link>
          <Typography key="3" color="text.primary">
            Edit
          </Typography>
        </Breadcrumbs>
        <br />

        <Typography variant="h5" component="h2" gutterBottom>
          セットの編集
        </Typography>
        <TextField
          type="string"
          label="setId"
          value={upperSetId}
          onChange={(e: any) => {
            setUpperSetId(e.target.value);
          }}
        />
        <Button onClick={setEditStart} variant="contained" size="large">
          セット編集
        </Button>
      </Container>
    );
  }
};
