import { Typography } from "@mui/material";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { db } from "../../../index";
import { Problem, RawProblem, V2Room } from "../../../type";
import { ScreenResultRanking } from "../../admin/component/ScreenResultRanking";
import { WaitProblem } from "../../admin/component/ScreenWaitProblem";
import { convertRawProblemToProblem } from "../common/convertProblem";
import V2Ranking from "../player/V2Ranking";
import { DisplayUrl } from "../screen/DisplayUrl";
import { ScreenDisplayExplanation } from "../screen/ScreenDisplayExplanation";
import { ScreenDisplayProblem } from "../screen/ScreenDisplayProblem";

let unsub = () => {
  return;
};

export const V2Screen: FC = (props) => {
  const [roomId, setRoomId] = useState<string>("");
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [startTime, setStartTime] = useState<number>(0);
  const urlParams = useParams<{ roomId: string; hash: string }>();
  const [serverTime, setServerTime] = useState<number>();
  const [currentProblem, setCurrentProblem] = useState<Problem>();
  const [room, setRoom] = useState<V2Room>();

  const setInfo = useCallback(async (roomId: string, roomData: V2Room) => {
    const probSetId = roomData.setId;
    const currentProblemId = roomData.currentProblemId;
    setRoom({
      ...roomData,
      currentProblemId: roomData.currentProblemId,
      displayMode: roomData.displayMode,
      probInd: roomData.probInd,
      page: roomData.page,
      closeImage: roomData.closeImage ?? false,
    });

    if (roomId === "" || currentProblemId === "") {
      return;
    }

    const tmpSubDoc = await getDoc(
      doc(db, "v2rooms", roomId, "submits", currentProblemId)
    );

    const setsDoc = await getDoc(doc(db, "sets", probSetId));
    const problems = setsDoc.data()?.problems as RawProblem[];
    const problem = problems.find((e) => e.problemId === currentProblemId);

    if (!problem) {
      return;
    }
    setCurrentProblem(convertRawProblemToProblem(problem, probSetId));

    const tmpSubDocData = tmpSubDoc.data();

    if (tmpSubDocData === undefined) {
      return;
    }

    setStartTime(tmpSubDocData.startTime.toDate().getTime() ?? 0);
  }, []);

  const [start, setStart] = React.useState(false);
  const onStart = useCallback(() => {
    setStart(true);
  }, [setStart]);

  useEffect(() => {
    if (!start) {
      return;
    }
    const interval = setInterval(() => {
      setServerTime(new Date().getTime());
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [start]);

  useEffect(() => {
    if (startTime === 0 || serverTime === undefined) {
      return;
    }
    if (!currentProblem) {
      return;
    }
    if (!room) {
      return;
    }
    const timeDiff: number = (serverTime - startTime) / 1000;
    const _remainingTime: number = Math.min(
      currentProblem.timeLimit,
      currentProblem.timeLimit - timeDiff + 1
    );
    if (_remainingTime <= 0) {
      setRemainingTime(0);
    } else {
      setRemainingTime(_remainingTime);
      const switchingIndex =
        currentProblem.problemImageSwitchingTime.findLastIndex(
          (e) => e <= timeDiff
        );
      if (room.page != switchingIndex + 1 && room.displayMode === "prob") {
        setRoom({
          ...room,
          page: switchingIndex + 1,
        });
      }
    }
  }, [startTime, serverTime, currentProblem, room]);

  useEffect(() => {
    onStart();
    (async () => {
      const tmpRoomId = urlParams.roomId ? urlParams.roomId : "";
      const tmpRoomDoc = await getDoc(doc(db, "v2rooms", tmpRoomId));
      const roomData = tmpRoomDoc.data() as V2Room;

      if (roomData) {
        setRoom({
          ...roomData,
          setId: roomData.setId,
          currentProblemId: roomData.currentProblemId,
          displayMode: roomData.displayMode,
          probInd: roomData.probInd,
          page: roomData.page,
          closeImage: roomData.closeImage ?? false,
        });
      }
      setRoomId(tmpRoomId);
      if (tmpRoomDoc.data()) {
        setInfo(tmpRoomId, tmpRoomDoc.data() as V2Room);
      }
    })();
  }, [db, urlParams.roomId]);

  useEffect(() => {
    unsub();
    if (roomId === "" || (room?.setId ?? "") === "") {
      return;
    }
    unsub = onSnapshot(
      doc(db, "v2rooms", roomId),
      { includeMetadataChanges: true },
      async (roomDoc) => {
        if (roomDoc.data()) {
          setInfo(roomId, roomDoc.data() as V2Room);
        }
      }
    );
    return () => {
      unsub();
    };
  }, [roomId, room, setInfo, db]);

  return (
    <BgCrown>
      {currentProblem && room && (
        <>
          {room.displayMode === "wait" && (
            <WaitProblem
              probInd={room.probInd}
              imgUrls={currentProblem.beforeImgUrl}
              page={room.page}
            />
          )}
          {room.displayMode === "prob" && (
            <ScreenDisplayProblem
              remainingTime={remainingTime}
              timeLimit={currentProblem.timeLimit}
              roomId={roomId}
              probId={room.currentProblemId}
              imgUrls={currentProblem.imgUrl}
              page={room.page}
              closeImage={room.closeImage ?? false}
            />
          )}
          {room.displayMode === "finishProblem" && <p>解説をお待ちください</p>}
          {room.displayMode === "explain" && (
            <ScreenDisplayExplanation
              answers={currentProblem.answers}
              roomId={roomId}
              probId={room.currentProblemId}
              imgUrls={currentProblem.answerImgUrl}
              page={room.page}
            />
          )}
          {room.displayMode === "explain-submission" && (
            <ScreenDisplayExplanation
              answers={currentProblem.answers}
              displaySubmission={true}
              roomId={roomId}
              probId={room.currentProblemId}
              imgUrls={currentProblem.answerImgUrl}
              page={room.page}
            />
          )}
          {room.displayMode === "url" && (
            <DisplayUrl roomId={roomId} version="v2room" />
          )}
          {room.displayMode === "ranking" && <V2Ranking />}
          {room.displayMode === "finish" && (
            <>
              <Typography variant="h3" sx={{ mt: 2 }}>
                最終結果
              </Typography>
              <ScreenResultRanking roomId={roomId} />
            </>
          )}
        </>
      )}
    </BgCrown>
  );
};

const BgCrown = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  min-width: 100vh;
  background-color: #fffffe;
  z-index: -1;
  background-image: linear-gradient(
      45deg,
      rgb(216, 237, 253, 0.4) 25%,
      transparent 25%
    ),
    linear-gradient(315deg, rgb(216, 237, 253, 0.4) 25%, #fffffe 25%);
  background-position: 30px 0, 60px 0, 0 0, 0 0;
  background-size: 60px 60px;
  background-repeat: repeat;
  color: #111;
`;
