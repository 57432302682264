import { Button, Grid, TextField } from "@mui/material";
import { FC, useCallback, useState } from "react";

import { googleSheetApiKey } from "../../../api/googleSheetApiKey";

type Props = {
  id: string;
  fetchValues: (values: { [key: string]: string[][] }) => void;
  sheetNames: string[];
};

export const MultipleSpreadSheetLoader: FC<Props> = (props) => {
  const { fetchValues, sheetNames } = props;

  const [spreadsheetInput, setSpreadsheetInput] = useState<string>("");

  const loadSpreadsheet = useCallback(
    async (inputUrl: string) => {
      // sheet v4
      const apiKey =
        process.env.REACT_APP_GOOGLE_SHEETS_API_KEY ?? googleSheetApiKey; // APIキー
      // check is url
      try {
        const url = new URL(inputUrl);
        const id = url.pathname.split("/")[3];

        // gid(SheetId) to sheetTitle
        await Promise.all(
          sheetNames.map(async (sheetName) => {
            console.log(sheetName, apiKey);
            const sheetUrl = `https://sheets.googleapis.com/v4/spreadsheets/${id}/values/${sheetName}?key=${apiKey}`;
            const sheetResponse = await fetch(sheetUrl);
            const sheetJson = await sheetResponse.json();
            return sheetJson.values;
          })
        ).then((values) => {
          console.log(values);
          const value = sheetNames.reduce((acc, sheetName, i) => {
            acc[sheetName] = values[i];
            return acc;
          }, {} as { [key: string]: string[][] });
          fetchValues(value);
        });

        setSpreadsheetInput("");
      } catch (e) {
        return;
      }
    },
    [
      fetchValues,
      process.env.REACT_APP_GOOGLE_SHEETS_API_KEY,
      googleSheetApiKey,
    ]
  );

  return (
    <>
      <Grid item sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="スプレッドシートURL"
          value={spreadsheetInput}
          onChange={(e) => setSpreadsheetInput(e.target.value)}
          sx={{ width: 400, mt: 1, mr: 2 }}
        />
        <Button
          variant="contained"
          sx={{ mt: 2, mr: 2 }}
          onClick={() => {
            loadSpreadsheet(spreadsheetInput);
          }}
        >
          読み込み
        </Button>
      </Grid>
    </>
  );
};
