import { Face as FaceIcon, Home as HomeIcon } from "@mui/icons-material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Box, Chip, Container, Toolbar } from "@mui/material";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { db } from "../../../index";
import { getServerTime } from "../../../lib/getServerTime";
import { Problem, RawProblem, Submit, V2Room } from "../../../type";
import { ScreenResult } from "../../admin/component/ScreenResult";
import { ScreenResultRanking } from "../../admin/component/ScreenResultRanking";
import { convertRawProblemToProblem } from "../common/convertProblem";
import { AnswerForm } from "./component/AnswerForm";
import { DisplayExplanation } from "./component/DisplayExplanation";
import { DisplayProblem } from "./component/DisplayProblem";
import { WaitProblem } from "./component/WaitProblem";

let unsub = () => {
  return;
};

export const V2Game: React.FC = (props) => {
  const [roomId, setRoomId] = useState<string>("");
  const [probSetId, setProbSetId] = useState<string>("");
  const [currentProblem, setCurrentProblem] = useState<Problem>();
  const [room, setRoom] = useState<V2Room>();
  const [playerName, setPlayerName] = useState<string>("");
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [immediateCheck, setImmediateCheck] = useState<boolean>();
  const [playerAnswer, setPlayerAnswer] = useState<string>("");
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [startTime, setStartTime] = useState<number>(0);
  const [sendMessage, setSendMessage] = useState<string>("");
  const [nowPoint, setNowPoint] = useState<number>(0);
  const [remainingSubmitCount, setRemainingSubmitCount] = useState<number>(0);
  const [finish, setFinish] = useState<boolean>(true);

  const urlParams = useParams<{ roomId: string; hash: string }>();

  const setInfo = useCallback(
    (roomId: string, roomData: V2Room) => {
      (async () => {
        const currentProblemId = roomData.currentProblemId;
        setRoom({
          ...roomData,
          currentProblemId: roomData.currentProblemId,
          displayMode: roomData.displayMode,
          probInd: roomData.probInd,
          page: roomData.page,
          closeImage: roomData.closeImage ?? false,
        });
        const probSetId = roomData.setId;

        if (currentProblemId == "") {
          return;
        }
        if (playerName === "") {
          return;
        }

        const subDoc = await getDoc(
          doc(db, "v2rooms", roomId, "submits", currentProblemId)
        );
        const setsDoc = await getDoc(doc(db, "sets", probSetId));
        const problems = setsDoc.data()?.problems as RawProblem[];
        const problem = problems.find((e) => e.problemId === currentProblemId);
        if (!problem) {
          return;
        }

        let tmpRemainingSubmitCount = parseInt(problem.answerCount);
        let tmpCorrect = false;
        const submitDoc = doc(
          db,
          "v2rooms",
          roomId,
          "submits",
          currentProblemId,
          "users",
          playerName
        );
        const submitDocSnap = await getDoc(submitDoc);
        if (submitDocSnap.exists() === true) {
          tmpRemainingSubmitCount = submitDocSnap.data()?.remainingSubmitCount;
          tmpCorrect = submitDocSnap.data()?.correct;
        } else {
          setDoc(submitDoc, {
            answer: "",
            remainingSubmitCount: tmpRemainingSubmitCount,
            submitTime: -1,
            correct: false,
            point: 0,
          });
        }
        setCurrentProblem(convertRawProblemToProblem(problem, probSetId));
        const tmpImmediateCheck = problem.judgeTiming === "入力直後";
        setStartTime(subDoc.data()?.startTime.toDate().getTime());
        setRemainingSubmitCount(tmpRemainingSubmitCount);

        // lockcountは不使用
        setPlayerAnswer("");
        setImmediateCheck(false && tmpImmediateCheck);
        setIsCorrect(submitDocSnap.data()?.correct);

        let tmpFinish = false;
        if (tmpRemainingSubmitCount === 0) tmpFinish = true;
        if (tmpCorrect) tmpFinish = true;
        if (tmpFinish && tmpImmediateCheck)
          setPlayerAnswer(submitDocSnap.data()?.answer);
        if (!tmpImmediateCheck) tmpFinish = false;
        setFinish(tmpFinish);
      })();
    },
    [probSetId, playerName]
  );

  const [serverTime, setServerTime] = React.useState<any>();
  const [serverTimeDiff, setServerTimeDiff] = React.useState<number>(0);

  const [start, setStart] = React.useState(false);
  const onStart = React.useCallback(() => {
    setStart(true);
  }, [setStart]);

  useEffect(() => {
    if (!start) {
      return;
    }
    getServerTime(playerName !== "" ? playerName ?? "player" : "player").then(
      (serverTime: Date) => {
        setServerTimeDiff(serverTime.getTime() - new Date().getTime());
      }
    );

    const interval = setInterval(() => {
      setServerTime(new Date(new Date().getTime() + (serverTimeDiff ?? 0)));
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [start]);

  useEffect(() => {
    if (!currentProblem) {
      return;
    }
    if (!room) {
      return;
    }
    const timeDiff: number = (serverTime - startTime) / 1000;
    const _remainingTime: number = Math.min(
      currentProblem.timeLimit,
      currentProblem.timeLimit - timeDiff + 1
    );
    if (_remainingTime <= 0) {
      setFinish(true);
      setRemainingTime(0);
      if (!finish) {
        setNowPoint(0);
      }
    } else {
      setRemainingTime(_remainingTime);
      // timeDiff to SwitchingIndex
      const switchingIndex =
        currentProblem.problemImageSwitchingTime.findLastIndex(
          (e) => e <= timeDiff
        );
      if (room?.page !== switchingIndex + 1) {
        setRoom((prev) => {
          if (!prev) {
            return prev;
          }
          return {
            ...prev,
            page: switchingIndex + 1,
          };
        });
      }

      if (!finish) {
        setNowPoint(
          currentProblem.baseScore[0] +
            currentProblem.scoreTimeCoefficient * _remainingTime
        );
      }
    }
  }, [startTime, serverTime]);

  useEffect(() => {
    onStart();
    (async () => {
      const tmpRoomId = urlParams.roomId ? urlParams.roomId : "";
      const tmpHash = urlParams.hash ? urlParams.hash : "";
      const hashDoc = await getDoc(
        doc(db, "v2rooms", tmpRoomId, "hash", tmpHash)
      );
      const tmpRoomDoc = await getDoc(doc(db, "v2rooms", tmpRoomId));
      setProbSetId(tmpRoomDoc.data()?.probSetId);
      setPlayerName(hashDoc.data()?.name ? hashDoc.data()?.name : "");
      setRoomId(tmpRoomId);
      if (tmpRoomDoc.data()) {
        setInfo(tmpRoomId, tmpRoomDoc.data() as V2Room);
      }
    })();
  }, []);

  useEffect(() => {
    if (roomId === "" || probSetId === "") {
      return;
    }
    unsub();
    unsub = onSnapshot(doc(db, "v2rooms", roomId), (roomDoc) => {
      if (roomDoc.data()) {
        setInfo(roomId, roomDoc.data() as V2Room);
      }
    });
    return () => {
      unsub();
    };
  }, [roomId, probSetId]);

  const check = useCallback(() => {
    if (roomId === "") {
      return;
    }
    if (!room) {
      return;
    }
    if (room.currentProblemId === "") {
      return;
    }
    if (playerName === "") {
      return;
    }
    if (playerAnswer === "") return;
    if (!currentProblem) {
      return;
    }
    const timestamp = new Date().getTime();
    const remainingTimeFine =
      currentProblem.timeLimit * 1000 - (timestamp - startTime);
    if (remainingTimeFine < 0) {
      return;
    }
    let ok = false;
    for (let i = 0; i < currentProblem.answers.length; i++) {
      if (currentProblem.answers[i] === playerAnswer) ok = true;
    }

    setDoc(
      doc(
        db,
        "v2rooms",
        roomId,
        "submits",
        room.currentProblemId,
        "users",
        playerName
      ),
      {
        answer: playerAnswer,
        remainingSubmitCount: remainingSubmitCount - 1,
        submitTime: remainingTimeFine,
        correct: ok,
        point: nowPoint,
      } as Submit
    );
    if (!immediateCheck) {
      setModalType("send");
      setSendMessage("あなたの回答：" + playerAnswer);
    } else if (ok) {
      setModalType("correct");
    } else {
      setModalType("incorrect");
    }
    setRemainingSubmitCount(remainingSubmitCount - 1);
    if (ok && immediateCheck) {
      setFinish(true);
      setIsCorrect(true);
      return;
    }
    if (remainingSubmitCount === 1) {
      setFinish(true);
      return;
    }
    setPlayerAnswer("");
  }, [
    roomId,
    room,
    playerName,
    playerAnswer,
    remainingSubmitCount,
    startTime,
    nowPoint,
    immediateCheck,
    currentProblem,
  ]);

  let subtitle: HTMLHeadingElement | null;
  const [modalType, setModalType] = React.useState<string>("");

  function afterOpenModal() {
    if (subtitle) subtitle.style.color = "#f00";
  }

  function closeModal() {
    setModalType("");
  }

  return (
    <BgCrown>
      <Toolbar>
        <Chip icon={<FaceIcon />} label={playerName} sx={{ mx: 1 }} />
        <Chip icon={<HomeIcon />} label={roomId} sx={{ mx: 1 }} />
        {room && (
          <Chip
            icon={<QuestionMarkIcon />}
            label={`第${room.probInd + 1}問`}
            sx={{ mx: 1 }}
          />
        )}
      </Toolbar>
      {currentProblem && room && (
        <Container
          style={{
            maxWidth: "100%",
            overflowY: "scroll",
          }}
        >
          <Box>
            {room.displayMode === "prob" && currentProblem && (
              <DisplayProblem
                immediateCheck={immediateCheck}
                isCorrect={isCorrect}
                remainingTime={remainingTime}
                timeLimit={currentProblem.timeLimit}
                nowPoint={nowPoint}
                imgUrls={currentProblem.imgUrl}
                page={room.page}
                closeImage={room.closeImage ?? false}
              />
            )}
            {room.displayMode === "finishProblem" && (
              <p>解説をお待ちください</p>
            )}
            {(room.displayMode === "explain" ||
              room.displayMode === "explain-submission") && (
              <DisplayExplanation
                isCorrect={isCorrect}
                answers={currentProblem.answers}
                imgUrls={currentProblem.answerImgUrl}
                page={room.page}
              />
            )}
            {room.displayMode === "wait" && (
              <WaitProblem
                remainingSubmitCount={remainingSubmitCount}
                probInd={room.probInd}
                imgUrls={currentProblem.beforeImgUrl}
                page={room.page}
              />
            )}
            {room.displayMode === "url" && (
              <WaitProblem
                remainingSubmitCount={remainingSubmitCount}
                probInd={room.probInd}
                imgUrls={currentProblem.beforeImgUrl}
                page={room.page}
              />
            )}
            {room.displayMode === "ranking" && (
              <WaitProblem
                remainingSubmitCount={remainingSubmitCount}
                probInd={room.probInd}
                imgUrls={currentProblem.beforeImgUrl}
                page={room.page}
              />
            )}
            {room.displayMode === "finish" && (
              <>
                <ScreenResult />
                <ScreenResultRanking roomId={roomId} />
              </>
            )}
            {remainingTime > 0 && (
              <AnswerForm
                inputType={currentProblem.inputType}
                playerAnswer={playerAnswer}
                remainingSubmitCount={remainingSubmitCount}
                setPlayerAnswer={setPlayerAnswer}
                displayMode={room.displayMode}
                finish={finish}
                check={check}
                choices={currentProblem.choices ? currentProblem.choices : []}
              />
            )}
            <div>
              <Modal
                contentLabel="Example Modal"
                isOpen={modalType === "correct"}
                style={customStyles}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
              >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                  正解！（+{nowPoint} point）
                </h2>
                <button onClick={closeModal}>OK</button>
              </Modal>
            </div>
            <div>
              <Modal
                contentLabel="Example Modal"
                isOpen={modalType === "incorrect"}
                style={customStyles}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
              >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>不正解...</h2>
                <button onClick={closeModal}>OK</button>
              </Modal>
            </div>
            <div>
              <Modal
                contentLabel="Example Modal"
                isOpen={modalType === "send"}
                style={customStyles}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
              >
                <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                  送信しました
                </h2>
                <p>{sendMessage}</p>
                <button onClick={closeModal}>OK</button>
              </Modal>
            </div>
          </Box>
        </Container>
      )}
    </BgCrown>
  );
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const BgCrown = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: #fffffe;
  z-index: -1;
  background-image: linear-gradient(
      45deg,
      rgb(216, 237, 253, 0.4) 25%,
      transparent 25%
    ),
    linear-gradient(315deg, rgb(216, 237, 253, 0.4) 25%, #fffffe 25%);
  background-position: 30px 0, 60px 0, 0 0, 0 0;
  background-size: 60px 60px;
  background-repeat: repeat;
  color: #111;
`;
